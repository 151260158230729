import React from "react";
import { useHistory } from "react-router-dom";
import { UserInfo } from "../../Types";

type Props = {
    data?: UserInfo,
    message?: string,
    stopSearch?: () => void,
    onClick?: () => void
}

const LiveSearchItem = (props: Props) => {
    const data = props.data;
    const history = useHistory();

    const loadProfile = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        props.stopSearch!();
        history.push(`/profile/${data!.userId}`);
    }

    const onMessageClicked = () => {
        if (props.onClick) {
            props.onClick();
        }
    }

    if (props.message) {
        return (
            <div className="live-search-card-container" onClick={onMessageClicked}>
                <div className="message" >
                    {props.message}
                </div>
            </div>
        )
    }

    return (
        <div className="live-search-card-container" onClick={loadProfile}>
            <img src={data!.picture} className="user-profile-picture"></img>

            <div className="profile-titles">
                <div className="name">{data!.name}</div>
                <div className="title">{data!.title || ""}</div>
            </div>
        </div>
    )
}

export default LiveSearchItem;


