import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import { updatePostLikeAsync } from '../../services/postsService';
import { handleGenericCounter } from '../../services/Helpers';
import { Post } from '../../Types';
import { GoComment } from 'react-icons/go';

type Props = {
    auth0Sub: string,
    data: Post,
    commentsCount: number
}

type State = {
    likePost: boolean,
    likesCount: number,
}

class PostButtons extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            likePost: this.props.data.likedByCurrentUser,
            likesCount: this.props.data.likesCount,
        };
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.handleBeforeUnload, true);
    }

    handleBeforeUnload = async () => {
        await this.processPostLike(true);
    }

    async componentWillUnmount() {
        window.removeEventListener("beforeunload", this.handleBeforeUnload, true);
        await this.processPostLike();
    }

    onLike = () => {
        var updated = handleGenericCounter(this.props.data.likedByCurrentUser, this.state.likePost, this.props.data.likesCount, this.state.likesCount);
        this.setState({ likePost: updated.newBool, likesCount: updated.newCount });
    }

    onComment = () => {
        try {
            (document.getElementsByClassName(`user-comment ${this.props.data.postId}`)[0].getElementsByClassName("MuiInputBase-input")[0] as HTMLInputElement).focus();
        } catch (error) { }
    }

    processPostLike = async (keepalive?: boolean) => {
        let updateLike = this.props.data.likedByCurrentUser != this.state.likePost;

        if (updateLike) {
            let payload = {
                postId: this.props.data.postId,
                userId: this.props.auth0Sub,
                like: this.state.likePost
            }

            await updatePostLikeAsync(payload, keepalive);
        }
    };

    render() {
        return (
            <div className="post-buttons">
                <div className="post-buttons-container">

                    <div className="likes-container">
                        {this.state.likePost ?
                            <Button onClick={this.onLike} className="button-filled" startIcon={<ThumbUpAltIcon />}>{this.state.likesCount}</Button>
                            :
                            <Button onClick={this.onLike} startIcon={<ThumbUpAltOutlinedIcon />}>{this.state.likesCount}</Button>
                        }
                    </div>

                    <Button onClick={this.onComment} startIcon={<GoComment />}>{this.props.commentsCount}</Button>
                </div>
                <hr />
            </div>
        );
    }
}

export default connect(null, {})(PostButtons);