import React, { useEffect, useState } from "react";
import Loading from '../Loading';
import DOMPurify from 'dompurify';
import { searchAsync } from '../../services/searchService';
import '../../styles/searchResults.scss';
import Pagination from '@material-ui/lab/Pagination';
import UserSearchCard from './UserSearchItem';
import { RouteComponentProps } from "react-router-dom";
import { SearchRequest } from "../../Types";
import { getDefaultSearchResult } from "./SearchBar";


const MaxUserSearchItemsPerPage = 20;
export default function SearchResults(props: RouteComponentProps) {

    const value = DOMPurify.sanitize(new URLSearchParams(props.history.location.search).get("value") || "");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [data, setData] = useState(getDefaultSearchResult());

    useEffect(() => {
        const getData = async () => {
            let payload: SearchRequest = {
                value,
                page: page - 1,
                max: MaxUserSearchItemsPerPage
            }
            let data = await searchAsync(payload);
            setData(data || getDefaultSearchResult());
            setLoading(false);
        }

        getData();
    }, [page, value]);


    if (loading) {
        return <Loading />
    }

    const ErrorMessage = () => (
        <div className="user-search-card-container">
            <div className="error">
                No results found for that search value
            </div>
        </div>
    );

    const requiredPages = data ? data.total < data.limit ? 1 : Math.ceil(data.total / data.limit) : 0;

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <div>
            <div className="search-results-container">

                {!data ? <ErrorMessage /> :
                    <div>
                        {data.users.map((user, index) => {
                            return (
                                <UserSearchCard key={user.userId} data={user} final={index + 1 === data.length} />
                            )
                        })}

                        {data.length === 0 && <ErrorMessage />}
                    </div>
                }

                {requiredPages >= 2 &&
                    <div className="pagination-container">
                        <Pagination count={requiredPages} onChange={handlePageChange} color="primary" />
                    </div>
                }

            </div>
        </div >
    );
}

