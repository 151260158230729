import React, { useEffect, useState } from 'react';

const withScrollPercentage = (Component: any) => (childProps: any) => {

    const [scrollPercentage, setScrollPercentage] = useState(0);

    const handleScroll = () => {
        requestAnimationFrame(() => {
            calculateScrollDistance();
        });
    }

    const calculateScrollDistance = () => {
        const scrollTop = window.pageYOffset; // how much the user has scrolled by
        const winHeight = window.innerHeight;
        const docHeight = getDocHeight();

        const totalDocScrollLength = docHeight - winHeight;
        const scrollPostion = Math.floor(scrollTop / totalDocScrollLength * 100)
        setScrollPercentage(scrollPostion);
    }

    const getDocHeight = () => {
        return Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        );
    }

    useEffect(() => {
        document.addEventListener("scroll",handleScroll);
        return () => document.removeEventListener('scroll', handleScroll)
    }, []);

    return (
        <Component scrollPercentage={scrollPercentage} {...childProps} />
    )
}

export default withScrollPercentage;