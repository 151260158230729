
import { configureStore } from "@reduxjs/toolkit";
import toastReducer from "./reducers/toastReducer";
import profileReducer from "./reducers/profileReducer";
import eventsReducer from "./reducers/eventsReducer";
import usersNotificationsReducer from "./reducers/usersNotificationsReducer";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import createSagaMiddleware from "redux-saga";
import notificationsSaga from "./sagas/usersNotificationSaga";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// https://redux.js.org/tutorials/fundamentals/part-8-modern-redux
const store = configureStore({
    reducer: {
        toast: toastReducer,
        profile: profileReducer,
        events: eventsReducer,
        usersNotifications: usersNotificationsReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware)
})


// then run the saga
sagaMiddleware.run(notificationsSaga)


// store.subscribe(() => console.log('Redux state changed!!'));
console.log("\nRedux store created");

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch


// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;