import React from "react";
import '../../styles/profile.scss'
import ProfileHeading from './ProfileHeading';
import Loading from '../Loading';
import { Post, Profile } from "../../Types";
import withProfile from "../HOC/withProfile";
import withScrollPercentage from "../HOC/withScrollPercentage";
import withPosts from "../HOC/withPosts";
import TimelinePost from "../TimelinePost";

interface Props {
    loadingProfile: boolean,
    isAuthenticated: boolean,
    profile: null | Profile,
    isPersonalProfile: boolean,
    viewerUserId: string,
    profileUserId: string,
    scrollPercentage: number,
    loadingPosts: boolean,
    posts: Post[]
}

function UserProfile(props: Props) {
    const { loadingProfile, isAuthenticated, profile, isPersonalProfile, viewerUserId, profileUserId, posts } = props;

    if (!isAuthenticated) {
        return null;
    } else if (loadingProfile) {
        return <Loading />
    }

    return (
        <div className="account-container">

            <ProfileHeading viewerUserId={viewerUserId} profileUserId={profileUserId} profile={profile!} isPersonalAccount={isPersonalProfile} />

            <div className="profile-body">

                <div className="posts-container">
                    <div className="posts-scroll">
                        {!posts || posts.length === 0 ?
                            <div className="empty-timeline">No posts have been uploaded by this user.</div>
                            :
                            posts.map((post) => {
                                return <TimelinePost auth0Sub={props.viewerUserId} key={post.postId} data={post} />
                            })
                        }
                    </div>
                </div>

            </div>

        </div>
    );
}

export default withProfile()(withScrollPercentage(withPosts("profile")(UserProfile)));