import React, { useEffect, useRef } from 'react';
import Popper from '@material-ui/core/Popper';
import '../../styles/popMenu.scss';

type MenuButtonProps = {
    icon?: any,
    text: string,
    onClick: () => void
}

const PopMenuButton = (props: MenuButtonProps) => {
    return (
        <button onClick={props.onClick} className="item" >
            {props.icon && <props.icon />}
            <span className="text">{props.text}</span>
        </button>
    )
}

type PopOverProps = {
    open: boolean,
    anchorEl: any,
    onClose: () => void,
    children: any
    hideOnScroll: boolean
}

const PopMenu = (props: PopOverProps) => {

    const closePopOver = () => {
        let popover = document.getElementsByClassName("simple-popover");

        if (props.hideOnScroll && popover.length && !popover[0].classList.contains("fixed")) {
            (popover[0] as HTMLDivElement).style.display = "none";
        }
        props.onClose();
    }

    const wrapperRef = useRef(null);

    useEffect(() => {
        const ref = wrapperRef as any;
        function handleClickOutside(event: { target: any; }) {
            if (ref.current && !ref.current.contains(event.target)) {
                closePopOver();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        document.body.addEventListener('touchstart', closePopOver, false);

        if (props.hideOnScroll) {
            document.body.onwheel = closePopOver;
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.body.removeEventListener('touchstart', closePopOver, false);

            if (props.hideOnScroll) {
                document.body.onwheel = null;
            }
        };
    }, [wrapperRef]);

    return (
        <>
            <Popper className={`simple-popover ${!props.hideOnScroll ? "fixed" : ""}`}
                ref={wrapperRef}
                open={props.open}
                anchorEl={props.anchorEl}
                transition placement="bottom-start"
                popperOptions={{ strategy: 'fixed' }}
                disablePortal={true}
            >
                <div className="paper-transition">
                    {props.children}
                </div>
            </Popper>
        </>
    )
}

export default PopMenu;
export { PopMenuButton };