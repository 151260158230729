import React from "react";
import loading from "./loading.svg";

// const Loading = () => (
//   <div className="spinner">
//     <img src={loading} alt="Loading" />
//   </div>
// );

const Loading = () => (
  <div className="spinner loader-1">
    <div className="loader-outter"></div>
    <div className="loader-inner"></div>
  </div>
);

export default Loading;
