import { createSlice } from '@reduxjs/toolkit'
import { NotificationItem } from '../../Types';

interface UsersNotificationsState {
    notifications: NotificationItem[]
}

const initialState: UsersNotificationsState = {
    notifications: new Array<NotificationItem>()
}

const notificationsSlice = createSlice({
    name: "usersNotifications",
    initialState,
    reducers: {
        fetchSucceded(state, action) {
            state.notifications = action.payload;
        },
        fetchFailed(state) {
            state.notifications = new Array<NotificationItem>();
        }
    }
});

const { fetchSucceded, fetchFailed } = notificationsSlice.actions;

export default notificationsSlice.reducer;
export { fetchSucceded, fetchFailed };
