import { ChangeEvent, useEffect, useReducer } from 'react';
import '../../styles/postFooter.scss';
import { createCommentAsync, getPostCommentsAsync } from '../../services/commentsService';
import { ReplyToComment, Comment } from '../../Types';
import { Completed, Loading, LoadingNextSet } from '../../redux/actionTypes';
import { IAction } from '../../redux/actions';

type ReplyTo = ReplyToComment & { name: string };

type State = {
    loading: boolean,
    userComment: string,
    replyingTo: ReplyTo | null,
    numberOfCommentsToShow: number,
    comments: Comment[],
    commentsCount: number,
    fetchedFromServer: boolean,
    continuationToken: string | null,
    loadingNextSet: boolean,
    page: number
};

const MaxInitial = 3;
const BlockCount = 10;
const MaxPageCount = 5;
const MaxCommentCharLength = 2000;

const UpdateUserComment = "UpdateUserComment";
const NewComment = "NewComment";
const DeleteComment = "DeleteComment";
const SetReplyTo = "SetReplyTo";
const UpdateNumberOfCommentsToShow = "UpdateNumberOfCommentsToShow";
const ClearInputs = "ClearInputs";

const footerReducer = (state: State, action: IAction): State => {
    switch (action.type) {
        case Loading: {
            return { ...state, loading: true };
        }
        case Completed: {
            return {
                ...state,
                loading: false,
                fetchedFromServer: true,
                continuationToken: action.payload.continueToken,
                loadingNextSet: false,
                page: state.page + 1,
                comments: [...state.comments, ...action.payload.comments!],
            };
        }
        case NewComment: {
            const updated = { ...state };
            updated.comments.unshift(action.payload);
            return { ...updated, commentsCount: updated.commentsCount + 1, userComment: "", numberOfCommentsToShow: updated.numberOfCommentsToShow + 1 };
        }
        case DeleteComment: {
            return { ...state, comments: state.comments.filter(c => c.commentId != action.payload!), commentsCount: state.commentsCount - 1 };
        }
        case LoadingNextSet: {
            return { ...state, loadingNextSet: true };
        }
        case UpdateUserComment: {
            return { ...state, userComment: action.payload! };
        }
        case SetReplyTo: {
            return { ...state, replyingTo: action.payload };
        }
        case UpdateNumberOfCommentsToShow: {
            return { ...state, numberOfCommentsToShow: action.payload };
        }
        case ClearInputs: {
            return { ...state, replyingTo: null, userComment: "" };
        }
        default: {
            return state;
        }
    }
}

function useComments(postId: string, totalCommentsCount: number) {

    const [state, dispatch] = useReducer(footerReducer, {
        loading: true,
        userComment: "",
        replyingTo: null,
        numberOfCommentsToShow: MaxInitial,
        comments: new Array<Comment>(),
        commentsCount: totalCommentsCount,
        fetchedFromServer: false,
        continuationToken: null,
        loadingNextSet: false,
        page: 0
    });

    const { userComment, replyingTo, numberOfCommentsToShow, comments, commentsCount, fetchedFromServer, continuationToken, loadingNextSet, page } = state;

    const getData = async () => {
        const { ok, result, continuationToken: newContinueToken } = await getPostCommentsAsync(postId, continuationToken, page)

        if (ok) {
            dispatch({
                type: Completed,
                payload: {
                    comments: Array.from(result),
                    continueToken: newContinueToken
                }
            });
        }
    }

    useEffect(() => {
        if (!fetchedFromServer) {
            getData();
        }

        if (fetchedFromServer && !loadingNextSet && comments.length < numberOfCommentsToShow && continuationToken && page < MaxPageCount) {
            dispatch({ type: LoadingNextSet });
            getData();
        }
    }, [comments]);

    const onCommentChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if ((e.target as any).value.length <= MaxCommentCharLength) {
            dispatch({ type: UpdateUserComment, payload: (e.target as any).value })
        }
    }

    const onCommentSubmit = async () => {
        let payload = {
            postId,
            content: userComment.trim(),
            replyTo: replyingTo ? replyingTo as ReplyToComment : null
        }

        let newComment: Comment = await createCommentAsync(payload);
        dispatch({ type: ClearInputs });

        if (newComment) {
            dispatch({ type: NewComment, payload: newComment });
        }
    }

    const onReply = (commentId: string) => {
        comments.forEach(c => {
            if (c.commentId === commentId) {
                dispatch({ type: SetReplyTo, payload: { userId: c.userId, commentId, name: c.name } });
            }
        });

        (document.getElementsByClassName(`user-comment ${postId}`)[0].getElementsByClassName("MuiInputBase-input")[0] as HTMLInputElement).focus();
    }

    const onCommentDeleted = (commentId: string) => {
        dispatch({ type: DeleteComment, payload: commentId });
    }

    const onCancelReply = () => {
        dispatch({ type: SetReplyTo, payload: null });
    }

    //Current comments to show in post
    const commentsToShow = comments.slice(0, numberOfCommentsToShow);

    const getPreviousNumberOfCommentsToShow = () => {
        let hiddenCommentsTotalCount = Math.max(commentsCount - numberOfCommentsToShow, 0);
        let showMore = 0;

        if (hiddenCommentsTotalCount > 0 && hiddenCommentsTotalCount > BlockCount) {
            showMore = BlockCount;
        } else if (hiddenCommentsTotalCount > 0) {
            showMore = hiddenCommentsTotalCount;
        }

        return showMore;
    }

    const onShowMoreComments = async () => {
        dispatch({
            type: UpdateNumberOfCommentsToShow,
            payload: numberOfCommentsToShow + getPreviousNumberOfCommentsToShow()
        });

        if (continuationToken && !loadingNextSet && page < MaxPageCount) {
            dispatch({ type: LoadingNextSet });
            getData()
        }
    }

    const showMore = getPreviousNumberOfCommentsToShow();
    commentsToShow.reverse();

    return [
        state,
        showMore,
        commentsToShow,
        onShowMoreComments,
        onCancelReply,
        onCommentDeleted,
        onReply,
        onCommentSubmit,
        onCommentChange
    ] as const;
}

export default useComments;
