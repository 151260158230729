import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import "@pathofdev/react-tag-input/build/index.css";
import NCancelIcon from "../NComponents/NCancelIcon";
import '../../styles/dialog.scss';


type ImageDialogProps = {
    handleClose: () => void,
    open: boolean,
    imageSrc: string
}

type ImageDialogState = {
}

class ImageDialog extends React.Component<ImageDialogProps, ImageDialogState> {
    constructor(props: ImageDialogProps) {
        super(props);
    }

    render() {
        const { open, imageSrc, handleClose } = this.props;
        
        return (
            <div>

                <Dialog maxWidth="lg" fullWidth={true} onClose={handleClose} open={open}>
                    <div className="dialog-header">
                        <div className="dialog-cancel"><NCancelIcon onClick={handleClose} /></div>
                    </div>

                    <DialogContent >
                        <div className="dialog-body">
                            <img className='image-preview' src={imageSrc} alt='' />
                        </div>
                    </DialogContent>
                </Dialog>

            </div >
        );
    }
}

export default ImageDialog;

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

