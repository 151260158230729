import { UserInfo } from '../Types';
import { Action, ActionCreator, Dispatch, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { getUserInfoAsync } from '../services/profileService';
import { AppDispatch, RootState } from './store';
import { FetchUsersNotifications, HideToast, ShowErrorToast, ShowInfoToast, ShowSuccessToast, ShowWarningToast, Submitting, UpdateProfile } from './actionTypes';

export interface IAction {
    type: string,
    payload?: any
}

//toast
export function showSuccessToastAction(payload: any): IAction {
    return { type: ShowSuccessToast, payload }
}

export function showErrorToastAction(payload: any): IAction {
    return { type: ShowErrorToast, payload }
}

export function showWarningToastAction(payload: any): IAction {
    return { type: ShowWarningToast, payload }
}

export function showInfoToastAction(payload: any): IAction {
    return { type: ShowInfoToast, payload }
}

export function hideToastAction(): IAction {
    return { type: HideToast }
}

export function submittingAction(): IAction {
    return { type: Submitting }
}

//profile
export function UpdateProfileAction(userInfo: UserInfo): AnyAction {
    return { type: UpdateProfile, payload: userInfo }
}

export function getSignedInUserInfoThunk(userId: string): ThunkAction<Promise<void>, RootState, unknown, IAction> {

    return async (dispatch: ThunkDispatch<RootState, unknown, IAction>, getState: () => RootState) => {
        const userInfo = await getUserInfoAsync(userId);

        if (userInfo) {
            dispatch({ type: UpdateProfile, payload: userInfo });
        }
    }
}

//users notitifications
export function fetchUsersNotificationsAction(userId: string) {
    return { type: FetchUsersNotifications, payload: userId }
}