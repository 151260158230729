import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/nav.scss';
import ProfileDropDown from './ProfileDropDown';
import { connect } from 'react-redux';
import PostDialog from '../CustomDialogs/PostDialog';
import SearchBar from '../Search/SearchBar';
import { NIconButton } from '../NComponents/NButton';
import { AiOutlineMessage } from "react-icons/ai";
import Notifications from './Notifications';


function Nav() {
    const [postDialogOpen, setPostDialogOpen] = useState(false);

    const handleDialogClickOpen = () => {
        setPostDialogOpen(true);
    };

    const handleDialogClose = () => {
        setPostDialogOpen(false);
    };

    return (
        <div className={"nav-container"}>

            <div className="nav-left">
                <div className="logo-container">
                    <Link to="/">
                        <img className="nav-logo" src="/logo.png" />
                        <span id="brand-name">Hikmasight</span>
                    </Link>
                    <span id="preview-text">(Preview)</span>
                </div>

                <div className="search-container">
                    <SearchBar />
                </div>
            </div>

            <div className="nav-right">

                <NIconButton icon={<AiOutlineMessage />}
                    id="post-btn"
                    mode="primary"
                    size="small"
                    onClick={handleDialogClickOpen} />

                <Notifications />
                <ProfileDropDown />

                {postDialogOpen && <PostDialog mode={"create"} open={postDialogOpen} handleClose={handleDialogClose} />}

            </div>

        </div>
    );
}

export default connect(null, null)(Nav)
