import React from 'react';
import '../styles/read.scss';
import profile_pic from '../imgs/profile_pic.jpg';
import read_wallpaper from '../imgs/read-wallpaper.jpg';
import { connect } from 'react-redux';

class Read extends React.Component {

    state = {}
    
    componentDidMount() {
    }

    render() {

        return (
            <div className="read-container">

                <div className="read-body">

                    <h1 className="read-h1">Two Books That Turned a 26-Year-Old Programmer Into a Billionaire</h1>
                    <h2 className="read-h2 subtitle" >Founder of Shopify on books that changed his life</h2>

                    <div className="author-container">
                        <img className="read-authors-image" alt="" src={profile_pic}></img>

                        <div>
                            <p id="read-author-name">Najm Dost</p>
                            <p id="read-date">August 22 2020</p>
                            <p id="read-date">20k reads</p>

                        </div>

                        <div className="stats-container">
                            <div className="stats-icon read-likes">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" /><path d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2zM9 9l4.34-4.34L12 10h9v2l-3 7H9V9zM1 9h4v12H1z" /></svg>
                                <p className="stats-p">10k</p>
                            </div>
                            <div className="stats-icon read-dislikes">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" /><path d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm0 12l-4.34 4.34L12 14H3v-2l3-7h9v10zm4-12h4v12h-4z" /></svg>
                                <p className="stats-p">104</p>
                            </div>
                            <div className="stats-icon read-comments">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM20 4v13.17L18.83 16H4V4h16zM6 12h12v2H6zm0-3h12v2H6zm0-3h12v2H6z" /></svg>
                                <p className="stats-p">200</p>
                            </div>
                        </div>
                    </div>

                    <div className="wallpaper-container">
                        <img className="wallpaper-img" alt="" src={read_wallpaper}></img>
                        <p className="wallpaper-reference">Some of the courses already available on ML University’s</p>
                    </div>

                    <div id="read-text">
                        <h2 className="read-h2" data-selectable-paragraph="">What are Neural networks?</h2>
                        <p className="read-p">Neural networks are set of algorithms inspired by the functioning of human brian. Generally when you open your eyes, what you see is called data and is processed by the Nuerons(data processing cells) in your brain, and recognises what is around you. That’s how similar the Neural Networks works. They takes a large set of data, process the data(draws out the patterns from data), and outputs what it is.</p>

                        <h2 className="read-h2" data-selectable-paragraph="">What are Neural networks?</h2>
                        <p className="read-p">Neural networks are set of algorithms inspired by the functioning of human brian. Generally when you open your eyes, what you see is called data and is processed by the Nuerons(data processing cells) in your brain, and recognises what is around you. That’s how similar the Neural Networks works. They takes a large set of data, process the data(draws out the patterns from data), and outputs what it is.</p>
                        <p className="read-p">Neural networks are set of algorithms inspired by the functioning of human brian. Generally when you open your eyes, what you see is called data and is processed by the Nuerons(data processing cells) in your brain, and recognises what is around you. That’s how similar the Neural Networks works. They takes a large set of data, process the data(draws out the patterns from data), and outputs what it is.</p>

                        <h2 className="read-h2" data-selectable-paragraph="">What are Neural networks?</h2>
                        <p className="read-p">Neural networks are set of algorithms inspired by the functioning of human brian. Generally when you open your eyes, what you see is called data and is processed by the Nuerons(data processing cells) in your brain, and recognises what is around you. That’s how similar the Neural Networks works. They takes a large set of data, process the data(draws out the patterns from data), and outputs what it is.</p>
                        <p className="read-p">Neural networks are set of algorithms inspired by the functioning of human brian. Generally when you open your eyes, what you see is called data and is processed by the Nuerons(data processing cells) in your brain, and recognises what is around you. That’s how similar the Neural Networks works. They takes a large set of data, process the data(draws out the patterns from data), and outputs what it is.</p>
                        <p className="read-p">Neural networks are set of algorithms inspired by the functioning of human brian. Generally when you open your eyes, what you see is called data and is processed by the Nuerons(data processing cells) in your brain, and recognises what is around you. That’s how similar the Neural Networks works. They takes a large set of data, process the data(draws out the patterns from data), and outputs what it is.</p>
                    </div>

                    <div id="read-tags-container">
                        <ul className="read-tags-list">
                            <li className="read-tag-li"><a href="/tag/machine-learning" className="read-tag-a">Machine Learning</a></li>
                            <li className="read-tag-li"><a href="/tag/deep-learning" className="read-tag-a">Deep Learning</a></li>
                            <li className="read-tag-li"><a href="/tag/data-science" className="read-tag-a">Data Science</a></li>
                            <li className="read-tag-li"><a href="/tag/artificial-intelligence" className="read-tag-a">Artificial Intelligence</a></li>
                            <li className="read-tag-li"><a href="/tag/python" className="read-tag-a">Python</a></li>
                        </ul>
                    </div>


                </div>
            </div>
        );
    }
}

export default connect(null, {  })(Read)