import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/nav.scss';
import '../../styles/read.scss';
import { useAuth0 } from '@auth0/auth0-react';
import PopMenu from '../CustomDialogs/PopMenu';
import { AiOutlineNotification } from "react-icons/ai";
import { NIconButton } from '../NComponents/NButton';
import { getFriendlyTime } from '../../services/Helpers';
import { NotificationItem } from '../../Types';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { fetchUsersNotificationsAction } from '../../redux/actions';


export default function Notifications() {
    const { user } = useAuth0();
    const [notificationsAnchor, setNotificationsAnchor] = useState(null);

    const handleClick = (event: any) => {
        setNotificationsAnchor(event.currentTarget as any);
    };

    const handleClose = () => {
        setNotificationsAnchor(null);
    };

    const notifications = useAppSelector(state => state.usersNotifications.notifications);
    const reduxDispatch = useAppDispatch();

    useEffect(() => {
        reduxDispatch(fetchUsersNotificationsAction(user!.sub!));
    }, []);

    return (
        <div>

            <NIconButton icon={<AiOutlineNotification />}
                id="notifications-btn"
                mode="primary"
                size="small"
                onClick={handleClick}
            />

            <PopMenu hideOnScroll={false} anchorEl={notificationsAnchor} onClose={handleClose} open={Boolean(notificationsAnchor)}>

                <div id="notifications-container">
                    <span id="title">Notifications</span>
                    <hr />

                    {notifications.length > 0 &&
                        notifications.map((n, index) => {
                            return (
                                <div key={n.notificationId}>
                                    <NotificationViewItem hideMenu={handleClose} data={n} />
                                    {index != notifications.length - 1 && <hr />}
                                </div>
                            )
                        })
                    }

                    {notifications.length == 0 && <div id="empty-notifications">Empty</div>}
                </div>

            </PopMenu>
        </div>
    );
}

interface Props {
    data: NotificationItem,
    hideMenu: () => void
}

const NotificationViewItem = (props: Props) => {

    const { picture, name, date, type, fromUserId } = props.data;

    let message = "";

    if (type == "PostLike") {
        message = ` liked your post`;
    } else if (type === "CommentLike") {
        message = ` liked your comment`;
    }

    return (
        <div className="notifications-item">

            <div>
                <Link to={`/profile/${fromUserId}`} onClick={props.hideMenu}>
                    <img className="picture" src={picture} />
                </Link>

                <div className="text"><span>{name}</span>{message}</div>
            </div>

            <div className="time">{getFriendlyTime(date)}</div>
        </div>
    )
}