import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { hideToastAction, IAction } from '../redux/actions';
import Slide from '@material-ui/core/Slide';
import { connect } from 'react-redux';
import { ToastState } from '../redux/reducers/toastReducer';
import { RootState } from '../redux/store';

function SlideTransition(props: any) {
    return <Slide {...props} direction="up" />;
}

type SnackProps = {
    hideToastAction: () => IAction,
    toast: ToastState
}

type SnackState = {}

class CustomSnackNotification extends React.Component<SnackProps, SnackState> {

    handleToastClose = (reason: any) => {
        if (reason === 'clickaway') {
            return;
        }

        if (!this.props.toast.submitting) {
            this.props.hideToastAction();
        }
    };

    render() {
        const { show, severity, message, submitting } = this.props.toast;

        return (
            <Snackbar TransitionComponent={SlideTransition} open={show} autoHideDuration={3000} onClose={this.handleToastClose}>

                <MuiAlert icon={submitting ? <SubmittingIcon /> : undefined} elevation={6} variant="filled" severity={severity} onClose={submitting ? undefined : this.handleToastClose} >
                    {message}
                </MuiAlert>
            </Snackbar>
        )
    }
}

const SubmittingIcon = () => {
    return (
        <div className="lds-dual-ring"></div>
    )
}

function mapStateToProps(state: RootState) {
    return {
        toast: state.toast
    };
}

export default connect(mapStateToProps, { hideToastAction })(CustomSnackNotification)
