import React from 'react';
import { getFriendlyTime, handleGenericCounter } from '../../../services/Helpers';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import { Comment, UpdateCommentLikeRequest } from '../../../Types';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { connect } from 'react-redux';
import { RootState } from '../../../redux/store';
import PopMenu, { PopMenuButton } from '../../CustomDialogs/PopMenu';
import { deleteCommentAsync, updateCommentLikeAsync } from '../../../services/commentsService';
import { ProfileState } from '../../../redux/reducers/profileReducer';
import { Link } from 'react-router-dom';

type CommentItemProps = {
    comment: Comment,
    onReply: (commentId: string) => void,
    viewerUserId: string,
    onCommentDeleted: (commentId: string) => void,
    loggedInUser: ProfileState,
}

type CommentItemState = {
    likeComment: boolean,
    likeCount: number,
    anchorEl: any
}

class CommentItem extends React.Component<CommentItemProps, CommentItemState> {

    constructor(props: CommentItemProps) {
        super(props);

        this.state = {
            likeComment: this.props.comment.likedByViewer,
            likeCount: this.props.comment.likeCount,
            anchorEl: null
        }
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.handleBeforeUnload, true);
    }

    handleBeforeUnload = async () => {
        await this.processCommentLike(true);
    }

    async componentWillUnmount() {
        window.removeEventListener("beforeunload", this.handleBeforeUnload, true);
        await this.processCommentLike();
    }

    onLike = () => {
        var updated = handleGenericCounter(this.props.comment.likedByViewer, this.state.likeComment, this.props.comment.likeCount, this.state.likeCount);
        this.setState({ likeComment: updated.newBool, likeCount: updated.newCount });
    }

    processCommentLike = async (keepalive?: boolean) => {
        let updateLike = this.props.comment.likedByViewer != this.state.likeComment;

        if (updateLike) {
            let payload: UpdateCommentLikeRequest = {
                commentId: this.props.comment.commentId,
                userId: this.props.viewerUserId,
                like: this.state.likeComment
            }

            await updateCommentLikeAsync(payload, keepalive);
        }
    };

    onReply = () => {
        this.props.onReply(this.props.comment.commentId);
    }

    onDotMenu = (event: React.MouseEvent) => {
        this.setState({ anchorEl: event?.currentTarget });
    }

    onDotMenuClose = () => {
        this.setState({ anchorEl: null });
    }

    onDelete = async () => {
        this.onDotMenuClose();
        let commentId = this.props.comment.commentId;

        //optimistic delete :)
        this.props.onCommentDeleted(commentId);
        deleteCommentAsync(commentId);
    }

    onCopyText = () => {
        this.onDotMenuClose();
        var text = this.props.comment.content;

        navigator.clipboard.writeText(text).then(function () {
            // console.log(`Async: Copying ${text} to clipboard was successful!`);
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    render() {
        const comment = this.props.comment;
        const createdByThisUser = comment.userId === this.props.viewerUserId;

        const pictureUrl = createdByThisUser ? this.props.loggedInUser.picture! : comment.picture;
        const name = createdByThisUser ? this.props.loggedInUser.name : comment.name;

        return (
            <div className="comment-item">
                <Link to={`/profile/${this.props.comment.userId}`}>
                    <img className="profile-pic" alt="" src={pictureUrl} />
                </Link>

                <div>
                    <div className="body-wrapper">

                        <div className="name-wrapper">
                            <div className="name">
                                <Link to={`/profile/${this.props.comment.userId}`} >
                                    <div className="user">{name}</div>
                                </Link>

                                {comment.replyToName && <div style={{ display: "contents" }}>
                                    <div className="reply-to">in reply to</div>

                                    <Link to={`/profile/${comment.replyToUserId}`} >
                                        <div className="name user">{comment.replyToName}</div>
                                    </Link>
                                </div>}

                                <div className="date">{getFriendlyTime(comment.createdAt)}</div>
                            </div>

                            {createdByThisUser && <MoreHorizIcon onClick={this.onDotMenu} className="comment-dot" />}

                            <PopMenu hideOnScroll={true} anchorEl={this.state.anchorEl} onClose={this.onDotMenuClose} open={Boolean(this.state.anchorEl)}>
                                <PopMenuButton onClick={this.onDelete} text="Delete" />
                                <PopMenuButton onClick={this.onCopyText} text="Copy Text" />
                            </PopMenu>
                        </div>

                        <div className="text">{comment.content}</div>
                    </div>

                    <div className="buttons">
                        <div className="like-wrapper">
                            <span className={this.state.likeComment ? "like-btn filled" : "like-btn"} onClick={this.onLike}>Like</span>

                            {this.state.likeCount >= 1 &&
                                <div className="like-icon">
                                    <div>&#8226;</div>
                                    <div>
                                        {this.state.likeComment ? <ThumbUpAltIcon /> : <ThumbUpAltOutlinedIcon />}
                                    </div>
                                    <div>{this.state.likeCount}</div>
                                </div>}
                        </div>

                        {!createdByThisUser && <React.Fragment><div className="verticalLine" /> <div className="reply-btn" onClick={this.onReply}>Reply</div></React.Fragment>}
                    </div>
                </div>

            </div >
        )
    }
}

function mapStateToProps(state: RootState) {
    return {
        viewerUserId: state.profile.userId,
        loggedInUser: state.profile
    };
}

export default connect(mapStateToProps, {})(CommentItem)
