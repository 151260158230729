import { GenericCounter } from "../Types";
import { DateTime } from 'luxon';

export function isValidHttpUrl(sample: string): boolean {
    let url;

    try {
        url = new URL(sample);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export function handleGenericCounter(originalBool: boolean, currentBool: boolean, originalCount: number, currentCount: number): GenericCounter {
    var result: GenericCounter;

    if (currentBool && originalBool) {

        result = { newBool: false, newCount: currentCount - 1 };

    } else if (currentBool && !originalBool) {

        result = { newBool: false, newCount: originalCount };

    } else if (!currentBool && originalBool) {

        result = { newBool: true, newCount: originalCount };

    } else if (!currentBool && !originalBool) {

        result = { newBool: true, newCount: originalCount + 1 };

    }

    return result!;
}

export function getFriendlyTime(timestamp: string): string {
    const date = DateTime.fromISO(timestamp);

    var now = DateTime.local();
    var diff = now.diff(date);

    let seconds = Math.round(diff.as("seconds"));
    let minutes = Math.round(diff.as("minutes"));
    let hours = Math.round(diff.as("hours"));
    let days = Math.round(diff.as("days"));
    let weeks = Math.round(diff.as("weeks"));
    let months = Math.round(diff.as("months"));
    let years = Math.round(diff.as("years"));

    // console.log(`seconds: ${seconds}, minutes: ${minutes}, hours: ${hours}, days: ${days}, weeks: ${weeks}, months: ${months}, years: ${years}`);
    let result = "";

    if (seconds <= 0) {
        result = `now`;
    } else if (seconds < 60) {
        result = `${seconds}s`;
    } else if (minutes < 60) {
        result = `${minutes}m`;
    } else if (hours < 24) {
        result = `${hours}h`;
    } else if (days < 7) {
        result = `${date.toLocaleString({ weekday: 'short' })} at ${date.toLocaleString(DateTime.TIME_SIMPLE)}`;
    } else if (weeks < 4) {
        result = `${weeks}w`;
    } else if (months < 12) {
        result = `${date.toLocaleString({ month: 'short', day: 'numeric' })}`;
    } else {
        result = `${date.toLocaleString(DateTime.DATE_MED)}`;
    }

    return result;
}