import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import Heading from './Heading';
import BodyContent from './BodyContent';
import Tiles from './Tiles';
import Footer from './Footer';


const LandingPage = () => {

    const { loginWithRedirect } = useAuth0()

    const onLogin = () => {
        loginWithRedirect();
    }

    return (
        <PageContainer>
            <Heading onLogin={onLogin} />
            <BodyContent />
            <Tiles />
            <Footer/>
        </PageContainer>
    )
}

export default LandingPage;


const PageContainer = styled.div`
    background-color: white;
    width: 100%;
    color: ${(props) => props.theme.colors.text};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    font-family: ${props => props.theme.fonts.main};

    #login-btn{
        margin-top: 40px;
        margin-bottom: 10px;
    }

    a{
        color: ${props => props.theme.colors.text};
        transition: 0.2s ease-in-out;

        &:hover{
            text-decoration: none;
            color: ${props => props.theme.colors.primaryDark};
        }
    }
`;