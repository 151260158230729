import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getUsersNotificationsAsync } from '../../services/notificationsService';
import { IAction } from '../actions';
import { FetchUsersNotifications } from '../actionTypes';
import { fetchFailed, fetchSucceded } from '../reducers/usersNotificationsReducer';


function* fetchNotificationsWorker(action: IAction): any {
    try {
        const notifications = yield call(getUsersNotificationsAsync, action.payload);
        yield put(fetchSucceded(notifications));
    } catch (e) {
        yield put(fetchFailed());
    }
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* notificationsSaga() {
    yield takeLatest(FetchUsersNotifications, fetchNotificationsWorker);
}

export default notificationsSaga;


/*
  Starts fetchUser on each dispatched `FetchUsersNotifications` action.
  Allows concurrent fetches of user.
*/
// function* notificationsSaga() {
//     yield takeEvery(FetchUsersNotifications, fetchNotificationsWorker);
// }
