import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/nav.scss';
import '../../styles/read.scss';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { RootState } from '../../redux/store';
import PopMenu, { PopMenuButton } from '../CustomDialogs/PopMenu';


export default function ProfileDropDown() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { logout, user } = useAuth0();
    const pictureUrl = useSelector((state: RootState) => state.profile.picture);

    const handleClick = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget as any);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onLogout = () => {
        sessionStorage.setItem('sessionAuthenticated', "false");
        logout({
            returnTo: window.location.origin
        });
    }

    return (
        <div>
            <img onClick={handleClick} className="nav-account-img" alt="" src={pictureUrl}></img>

            <PopMenu hideOnScroll={false} anchorEl={anchorEl} onClose={handleClose} open={Boolean(anchorEl)}>

                <div id="profile-menu">
                    <Link className="menu-item-link" to={`/profile/${user!.sub!}`} >
                        <PopMenuButton icon={AccountCircleOutlinedIcon} onClick={handleClose} text="My profile" />
                    </Link>
                    <PopMenuButton icon={MeetingRoomIcon} onClick={onLogout} text="Sign out" />
                </div>

            </PopMenu>
        </div>
    );
}