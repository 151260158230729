export const Loading = "Loading";
export const LoadingNewProfile = "LoadingNewProfile";
export const Completed = "Completed";
export const NewPostCreated = "NewPostCreated";
export const PostDeleted = "PostDeleted";
export const PostUpdated = "PostUpdated";
export const LoadingNextSet = "LoadingNextSet";
export const EventsReset = "EventsReset";
export const UpdateFromRedux = "UpdateFromRedux";

export const ShowSuccessToast = "showSuccessToast";
export const ShowErrorToast = "showErrorToast";
export const ShowWarningToast = "showWarningToast";
export const ShowInfoToast = "showInfoToast";
export const HideToast = "hideToast";
export const Submitting = "submitting";

export const UpdateProfile = "updateProfile";

export const FetchUsersNotifications ="fetchUsersNotifications";
