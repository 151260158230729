import React from "react"
import styled from "styled-components";


const Tiles = () => {

    return (

        <TilesContainer >
            <div className="tile">
                <p className="poem"><i>"You wander from room to room, hunting for the diamond necklace, that is already around your neck."</i></p>
                <p className="author"><i>Rumi</i></p>
            </div>

            <div className="tile">
                <p className="poem"><i>"In everybody there is a piece of flesh, if it is healthy, the whole body is healthy, and if it is sick, the whole body is sick.
                    Beware! it is the heart!"</i></p>
                <p className="author"><i>Prophet Muhammad pbuh</i></p>
            </div>

            <div className="tile">
                <p className="poem"><i>"The meaning of life is to find your gift. The purpose of life is to give it away."</i></p>
                <p className="author"><i>Pablo Picasso</i></p>
            </div>
        </TilesContainer>
    )
}

export default Tiles;


const TilesContainer = styled.div`
    padding: 25px;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;

    .tile{
        padding: 15px;
        width: 350px;
        height: 200px;
        text-align: center;
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
        box-shadow: ${props => props.theme.shadows.soft};
        background-color: #fff;

        &:hover{
            box-shadow: 0 30px 50px rgba(232, 80, 91,0.10);
        }

        &:nth-child(2){
            margin-left: 40px;
            margin-right: 40px;
        }

        .poem{
            margin-top: 20px;
        }

        .author{
            font-size: 15px;
            color:#595959;
        }
    }

    @media only screen and (max-width: 1024px) {
        .tile{
            height: auto;
            width: 100%;

            &:nth-child(2){
                margin-top: 40px;
                margin-bottom: 40px;
                margin-left: auto;
                margin-right: auto;
            }
        }
        &{
            flex-direction: column;
        }
    }
`;