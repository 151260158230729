import { FollowRequest, Profile, UpdateProfileRequest, UserInfo } from '../Types';
import { callApiWithOkAsync, callApiWithResponseAsync } from './commonServices';

export async function getUserInfoAsync(userId: string): Promise<UserInfo> {
    return await callApiWithResponseAsync("getUserInfo", `/api/v1/profile/info/${userId}`, {
        method: 'GET'
    });
}

export async function followUserAsync(payload: FollowRequest, asynchronous: boolean = false): Promise<void> {

    await callApiWithOkAsync("followUser", "/api/v1/metadata/follow", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload),
        keepalive: asynchronous
    });
}

export async function updateUserProfileAsync(payload: UpdateProfileRequest): Promise<Profile> {

    const formData = new FormData();
    formData.append("imageChanged", `${payload.imageChanged}`);
    formData.append("userId", payload.userId);
    formData.append("name", payload.name);
    formData.append("existingPictureUrl", payload.existingPictureUrl);

    if (payload.title) {
        formData.append("title", payload.title);
    }

    if (payload.imageFile && payload.imageChanged) {
        formData.append('formfile', payload.imageFile);
        formData.append('filetype', payload.imageFile.type);
    }

    return await callApiWithResponseAsync("updateUserProfile", "/api/v1/profile", {
        method: 'POST',
        body: formData
    });
}

export async function getUserProfileAsync(userId: string): Promise<Profile | null> {
    return callApiWithResponseAsync("getUserProfile", `/api/v1/profile/${userId}`, {
        method: 'GET'
    });
}