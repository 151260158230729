import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import '../../styles/searchBar.scss';
import { searchAsync } from '../../services/searchService';
import LiveSearchItem from './LiveSearchItem';
import { SearchResult, UserInfo } from '../../Types';

const useStyles = makeStyles((theme) => ({
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    }
}));

var searchLoading = false;

export const getDefaultSearchResult = () => {
    return {
        limit: 0,
        length: 0,
        total: 0,
        users: new Array<UserInfo>()
    } as SearchResult
}

const MaxSearchBarItems = 8;

function SearchBar() {
    const classes = useStyles();
    const history = useHistory();

    let fromQuery = new URLSearchParams(history.location.search).get("value");
    const [value, setValue] = useState(fromQuery || "");
    const MaxSearchLength = 80;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [data, setData] = useState(getDefaultSearchResult());

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.value.length <= MaxSearchLength) {
            setValue(e.target.value);

            if (!e.target.value || e.target.value.trim() === "") {
                setData(getDefaultSearchResult());
            } else {
                search(e.target.value);
            }
        }
        if (!anchorEl) {
            onFocus(e);
        }
    }

    const stopSearch = () => {
        setAnchorEl(null);
        if (document.getElementById("dark-overlay")) {
            (document.getElementById("dark-overlay") as HTMLDivElement).style.display = "none";
        }
    }

    const onFocus = (e: any) => {
        setAnchorEl(e.currentTarget);
        if (document.getElementById("dark-overlay")) {
            (document.getElementById("dark-overlay") as HTMLDivElement).style.display = "block";
        }
    }

    const renderSearchPage = () => {
        stopSearch();
        if (value && value.trim().length >= 1) {
            history.push(`/search?value=${value}`);
        }

        (document.getElementById("search-input") as HTMLInputElement).blur();
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === "Enter") {
            renderSearchPage();
        }
    }

    const onShowMore = () => {
        renderSearchPage();
    }

    const search = async (searchValue: string) => {
        if (!searchValue || searchLoading) {
            return;
        }

        searchLoading = true;
        let payload = {
            value: searchValue,
            page: 0,
            max: MaxSearchBarItems
        }

        let data = await searchAsync(payload);
        setData(data || getDefaultSearchResult());
        searchLoading = false;
    };

    const onDocumentClick = (e: any) => {
        if (e.target.id !== ("search-input")) {
            stopSearch();
        }
    }

    useEffect(() => {
        document.addEventListener("click", onDocumentClick);
        return () => document.removeEventListener("click", onDocumentClick);
    }, []);

    const message = !value ? "Try searching" : "No results found";

    return (
        <div className="search-wrapper">
            <div className="search-icon">
                <SearchIcon />
            </div>

            <InputBase
                id="search-input"
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                placeholder="Search…"
                autoComplete="off"
                className="search-input"
                classes={{
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
            />

            <Popper className="live-dropdown-container" open={Boolean(anchorEl)} anchorEl={anchorEl} >
                <Paper>
                    <div className="live-content">
                        {(data == null || data.length == 0) && <LiveSearchItem message={message} />}


                        {(data && data.length > 0) &&
                            <div>
                                {data.users.map((user) => {
                                    return (
                                        <LiveSearchItem stopSearch={stopSearch} key={user.userId} data={user} />
                                    )
                                })}
                            </div>
                        }

                        {data && data.total > MaxSearchBarItems && <LiveSearchItem message="Show More Results" onClick={onShowMore} />}

                    </div>
                </Paper>
            </Popper>

        </div>
    );
}

export default SearchBar;
