import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import Nav from './components/Menu/Nav';
import Home from './components/Home';
import Read from './components/Read';
import Loading from './components/Loading';
import './styles/app.scss';
import { withRouter } from 'react-router-dom';
import Profile from './components/UserProfile';
import { connect } from 'react-redux';
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { AccessToken, setTokens } from './auth/AuthenticatedUser';
import CustomSnackNotification from './components/CustomSnackNotification';
import SearchResults from './components/Search';
import { RootState } from './redux/store';
import SingleThread from './components/Home/SingleThread';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LandingPage from './components/LandingPage';


interface AppProps extends WithAuth0Props, RouteComponentProps {
  profilePicture: null | string
}

interface AppState { }

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      userTokensSet: false
    }
  }

  userTokensSet = () => {
    this.setState({ userTokensSet: true });
  }

  render() {
    const { isLoading, isAuthenticated, loginWithRedirect } = this.props.auth0; //useAuth0();

    let sessionAuthenticated = sessionStorage.getItem('sessionAuthenticated') == "true";

    if (!isLoading && !isAuthenticated && sessionAuthenticated) {
      loginWithRedirect();
      return <Loading />;
    }

    if (isLoading) {
      return <Loading />;
    } else if (!isAuthenticated) {
      return <LandingPage />
    } else if (AccessToken === null) {
      setTokens(this.props.auth0, this.userTokensSet);
      return <Loading />;
    } else if (!this.props.profilePicture) {
      return <Loading />;
    }

    return (
      <div className="app-container">
        {isAuthenticated &&
          <>
            <Nav />
            <div id="dark-overlay" />

            <div className="app-body">
              <Switch>
                <Route exact path='/' component={Home} />

                <Route exact path='/threads/:postId' component={SingleThread} />

                <Route path="/profile/:userId?" component={Profile} />

                <Route path="/search" component={SearchResults} />

                <Route path='/read' component={() => (<Read />)} />
              </Switch>
            </div>

            <CustomSnackNotification />
          </>}
      </div >
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    profilePicture: state.profile.picture
  };
}

export default (connect(mapStateToProps, {})(withRouter(withAuth0(App))));