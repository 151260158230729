import { Auth0ContextInterface } from '@auth0/auth0-react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getSignedInUserInfoThunk } from '../redux/actions';
import store, { RootState } from '../redux/store';

var AccessToken: string | null = null;

async function setTokens(auth0: Auth0ContextInterface, userTokensSet: () => void) {
    AccessToken = await auth0.getAccessTokenSilently();
    sessionStorage.setItem('sessionAuthenticated', `${AccessToken != null}`);

    (store.dispatch as ThunkDispatch<RootState, {}, AnyAction>)(getSignedInUserInfoThunk(auth0.user!.sub!))
    userTokensSet();
}


export { AccessToken, setTokens }