import { ContinuationTokenHeader } from '../Constants';
import { CreatePostRequest, Post, PostAbuseRequest, UpdatePostLikeRequest, UpdatePostRequest } from '../Types';
import { ApiResponse, callApiWithOkAsync, callApiWithPaginatedResponseAsync, callApiWithResponseAsync } from './commonServices';


export async function getPostsByUserAsync(userId: string, continuationToken: string | null, page: number): Promise<ApiResponse> {
    return await callApiWithPaginatedResponseAsync(`getPostsByUser, Page: ${page}`, `/api/v1/posts/user/${userId}`, {
        method: 'GET',
        headers: continuationToken ? { [ContinuationTokenHeader]: continuationToken } : {}
    });
}

export async function getSinglePostAsync(postId: string): Promise<Post> {
    return await callApiWithResponseAsync("getSinglePost", `/api/v1/posts/${postId}`, { method: 'GET' });
}

export async function getTimelinePostsAsync(continuationToken: string | null, page: number): Promise<ApiResponse> {
    return await callApiWithPaginatedResponseAsync(`getTimelinePosts, Page: ${page}`, `/api/v1/posts`, {
        method: 'GET',
        headers: continuationToken ? { [ContinuationTokenHeader]: continuationToken } : {}
    });
}

export async function reportPostAbuseAsync(payload: PostAbuseRequest): Promise<boolean> {

    return await callApiWithOkAsync('reportPostAbuse', `/api/v1/metadata/abuse`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });
}

export async function updatePostLikeAsync(payload: UpdatePostLikeRequest, synchronous = false): Promise<void> {

    await callApiWithOkAsync('updatePostLike', `/api/v1/posts/like`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload),
        keepalive: synchronous
    });
}

export async function createNewPostAsync(payload: CreatePostRequest): Promise<Post> {

    const formData = new FormData();
    formData.append("content", payload.content);

    if (payload.tags.length >= 1) {
        formData.append("tags", JSON.stringify(payload.tags));
    }

    if (payload.file) {
        formData.append('formfile', payload.file);
        formData.append('filetype', payload.file.type);
    }

    return await callApiWithResponseAsync("createNewPost", "/api/v1/posts", {
        mode: "cors",
        method: 'POST',
        cache: 'no-cache',
        credentials: 'omit',
        body: formData
    });
}

export async function updatePostAsync(payload: UpdatePostRequest): Promise<Post> {

    const formData = new FormData();
    formData.append("postId", payload.postId);
    formData.append("content", payload.content);
    formData.append("imageChanged", `${payload.imageChanged}`);

    if (payload.tags.length >= 1) {
        formData.append("tags", JSON.stringify(payload.tags));
    }

    if (payload.imageFile && payload.imageChanged) {
        formData.append('formfile', payload.imageFile);
        formData.append('filetype', payload.imageFile.type);
    }

    return await callApiWithResponseAsync("updatePost", `/api/v1/posts`, {
        method: 'PUT',
        body: formData
    });
}

export async function deletePostAsync(postId: string): Promise<boolean> {
    return await callApiWithOkAsync("deletePost", `/api/v1/posts?postId=${postId}`, { method: 'Delete' });
}