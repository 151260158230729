import React from 'react';
import '../../styles/read.scss';
import '../../styles/post.scss';
import { connect } from 'react-redux';
import PostFooter from './PostFooter';
import { Post } from '../../Types';
import PostBody from './PostBody';
import PostHeading from './PostHeading';

type Props = {
    auth0Sub: string,
    data: Post
}

type State = {
    commentsCount: number
}

class TimelinePost extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            commentsCount: props.data.totalCommentsCount
        };
    }

    onCommentDeleted = () => {
        this.setState({ commentsCount: this.state.commentsCount - 1 });
    }

    render() {

        return (
            <div className="timeline-post-container">
                <PostHeading data={this.props.data} auth0Sub={this.props.auth0Sub} />
                <PostBody data={this.props.data} />
                <PostFooter auth0Sub={this.props.auth0Sub} data={this.props.data} />
            </div>
        );
    }
}

export default connect(null, {})(TimelinePost)