import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useAuth0 } from '@auth0/auth0-react';
import { reportPostAbuseAsync } from '../../services/postsService'
import { useDispatch } from 'react-redux';
import { showInfoToastAction } from '../../redux/actions';
import '../../styles/dialog.scss';
import NCancelIcon from '../NComponents/NCancelIcon';
import NButton from '../NComponents/NButton';


type Props = {
    open: boolean,
    postId: string,
    onClose: () => void
}

export default function AbuseDialog(props: Props) {

    const { open, onClose, postId } = props;
    const [reason, setReason] = useState("");
    const { user } = useAuth0();

    const dispatch = useDispatch()

    const onFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setReason((e.target as any).value);
    }

    const onReportAbuse = async () => {
        if (reason.trim().length === 0) {
            document.getElementById("reason-field")?.focus();
            return;
        }

        let report = {
            postId,
            userId: user!.sub!,
            reason
        }

        if (await reportPostAbuseAsync(report)) {
            dispatch(showInfoToastAction("Your abuse complaint has been made."));
        }
        onClose();
    }

    return (
        <div>
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <div className="dialog-header">
                    <div className="dialog-title">Report abuse</div>
                    <div className="dialog-cancel"><NCancelIcon onClick={onClose} /></div>
                </div>

                <DialogContent>
                    <div className="dialog-body">
                        <p id="report-abuse-info">
                            Please provide a brief explanation as to why you think this post is inappropriate. Your report will be reviewed as soon as possible.
                        </p>

                        <TextField
                            autoFocus
                            margin="dense"
                            id="reason-field"
                            label="Reason"
                            fullWidth
                            rowsMax={5}
                            multiline
                            value={reason}
                            onChange={onFieldChange}
                        />
                    </div>

                </DialogContent>

                <div className="dialog-footer">
                    <NButton style={{ width: "80px" }} mode="secondary" size="small" onClick={onClose} text="Cancel" />
                    <NButton style={{ width: "80px" }} mode="primary" size="small" onClick={onReportAbuse} text="Report" />
                </div>
            </Dialog>
        </div>
    );
}
