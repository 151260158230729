import { IAction } from '../actions';

export type ProfileState = {
    userId: null | string,
    name: null | string,
    title: null | string,
    picture: null | string
}

const profileState: ProfileState = {
    userId: null,
    name: null,
    title: null,
    picture: null
}

export default function profileReducer(state = profileState, action: IAction) {

    if (action.type === "updateProfile") {
        return { ...state, picture: action.payload.picture, title: action.payload.title, name: action.payload.name, userId: action.payload.userId };
    } 

    return state;
}

