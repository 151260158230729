import React from 'react';
import '../../styles/home.scss';
import TimelinePost from '../TimelinePost';
import { useAuth0 } from '@auth0/auth0-react';
import TimelineSkeleton from '../TimelinePost/TimelineSkeleton';
import { Post } from '../../Types';
import withPosts from '../HOC/withPosts';
import withScrollPercentage from '../HOC/withScrollPercentage';

type Props = {
    loadingPosts: boolean,
    posts: Post[]
}

function Timeline(props: Props) {
    const { user } = useAuth0();
    const { loadingPosts, posts } = props;

    return (
        <div className="home-main-container">
            <div className="timeline-scroll">

                {loadingPosts ?
                    (<div><TimelineSkeleton /><TimelineSkeleton /><TimelineSkeleton /></div>)
                    :
                    posts.length === 0 ? <div className="empty-timeline">Posts collection is empty.</div> :
                        posts.map((post) => {
                            return <TimelinePost auth0Sub={user!.sub!} key={post.postId} data={post} />
                        })
                }
            </div>
        </div >
    );
}

export default withScrollPercentage(withPosts("timeline")(Timeline));