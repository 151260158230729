import React from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import LinkIcon from '@material-ui/icons/Link';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { deletePostAsync } from '../../services/postsService';
import { IAction, showInfoToastAction } from '../../redux/actions';
import PostDialog from '../CustomDialogs/PostDialog';
import AbuseDialog from '../CustomDialogs/AbuseDialog';
import { Post } from '../../Types';
import { getFriendlyTime } from '../../services/Helpers';
import PopMenu, { PopMenuButton } from '../CustomDialogs/PopMenu';
import { postDeleted } from '../../redux/reducers/eventsReducer';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { ProfileState } from '../../redux/reducers/profileReducer';

interface Props {
    auth0Sub: string,
    data: Post
    showInfoToastAction: (payload: any) => IAction,
    postDeleted: ActionCreatorWithPayload<any, string>,
    loggedInUser: ProfileState
}

type State = {
    anchorEl: any | null,
    abuseDialogOpen: boolean,
    editDialogOpen: boolean
}

class PostHeading extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            anchorEl: null,
            abuseDialogOpen: false,
            editDialogOpen: false
        };
    }

    dotMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    dotMenuClose = (event?: React.MouseEvent<Document, MouseEvent>) => {
        this.setState({ anchorEl: null });
    };

    postCreatedByCurrentUser = () => {
        return this.props.auth0Sub === this.props.data.userId;
    }

    onDelete = async () => {
        this.dotMenuClose();

        //optimistic delete :)
        if (this.postCreatedByCurrentUser()) {
            this.props.postDeleted(this.props.data);
            deletePostAsync(this.props.data.postId);
            this.props.showInfoToastAction("Post deleted.");
        }
    }

    onEdit = () => {
        this.dotMenuClose();
        if (this.postCreatedByCurrentUser()) {
            this.setState({ editDialogOpen: true });
        }
    }

    onEditDialogClose = () => {
        this.setState({ editDialogOpen: false });
    };

    onReportAbuse = () => {
        this.dotMenuClose();
        this.setState({ abuseDialogOpen: true });
    }

    onAbuseDialogClose = () => {
        this.setState({ abuseDialogOpen: false });
    }

    onCopyLink = () => {
        this.dotMenuClose();
        const url = new URL(`${window.location.origin}/threads/${this.props.data.postId}`)
        var text = url.href;

        navigator.clipboard.writeText(text).then(function () {
            // console.log(`Async: Copying ${text} to clipboard was successful!`);
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    render() {
        const postCreatedByThisUser = this.postCreatedByCurrentUser();
        const pictureUrl = postCreatedByThisUser ? this.props.loggedInUser.picture! : this.props.data.picture;
        const name = postCreatedByThisUser ? this.props.loggedInUser.name : this.props.data.name;

        return (
            <div>
                <div className="post-heading">
                    <Link to={`/profile/${this.props.data.userId}`}>
                        <img className="post-profile-pic" alt="" src={pictureUrl}></img>
                    </Link>

                    <div className="post-author">
                        <Link to={`/profile/${this.props.data.userId}`} >
                            <div>
                                <span className="author-name">{name}</span>
                            </div>
                        </Link>
                        <div>
                            <span className="post-date">{getFriendlyTime(this.props.data.createdAt)}</span>
                            {this.props.data.edited && <span className="post-edited"><span className="bulletpoint">&#8226;</span>Edited</span>}
                        </div>
                    </div>

                    <div className="dot-menu">
                        <IconButton onClick={this.dotMenuClick}><MoreHorizIcon /></IconButton>

                        <PopMenu hideOnScroll={true} anchorEl={this.state.anchorEl} onClose={this.dotMenuClose} open={Boolean(this.state.anchorEl)}>
                            {postCreatedByThisUser && <PopMenuButton icon={EditOutlinedIcon} onClick={this.onEdit} text="Edit" />}
                            {postCreatedByThisUser && <PopMenuButton icon={DeleteOutlineIcon} onClick={this.onDelete} text="Delete" />}
                            <PopMenuButton onClick={this.onCopyLink} icon={LinkIcon} text="Copy Link" />
                            {!postCreatedByThisUser && <PopMenuButton icon={ReportProblemOutlinedIcon} onClick={this.onReportAbuse} text="Report Abuse" />}
                        </PopMenu>
                    </div>
                </div>

                {this.state.editDialogOpen && <PostDialog data={this.props.data} mode={"edit"} open={this.state.editDialogOpen} handleClose={this.onEditDialogClose} />}
                {this.state.abuseDialogOpen && <AbuseDialog open={this.state.abuseDialogOpen} postId={this.props.data.postId} onClose={this.onAbuseDialogClose} />}
            </div>
        );
    }
}

function mapStateToProps(state: RootState) {
    return {
        loggedInUser: state.profile
    };
}

export default connect(mapStateToProps, { showInfoToastAction, postDeleted })(PostHeading);