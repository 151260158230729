import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPostsByUserAsync, getTimelinePostsAsync } from '../../services/postsService';
import { RootState, useAppDispatch } from '../../redux/store';
import { Post } from '../../Types';
import { IAction } from '../../redux/actions';
import { Completed, Loading, LoadingNextSet, EventsReset, LoadingNewProfile } from '../../redux/actionTypes';
import { clearEvents } from '../../redux/reducers/eventsReducer';

type State = {
    loading: boolean;
    posts: Post[],
    fetchedFromServer: boolean,
    continuationToken: string | null,
    loadingNextSet: boolean,
    page: number,
    eventsReset: boolean
};

const postsReducer = (state: State, action: IAction): State => {
    switch (action.type) {
        case Loading: {
            return { ...state, loading: true };
        }
        case LoadingNewProfile: {
            return { ...state, loading: true, posts: new Array<Post>(), fetchedFromServer: false, page: 0, continuationToken: null, loadingNextSet: false, };
        }
        case Completed: {
            return {
                ...state,
                loading: false,
                fetchedFromServer: true,
                posts: [...state.posts, ...action.payload.posts!],
                continuationToken: action.payload.continueToken,
                loadingNextSet: false,
                page: state.page + 1
            };
        }
        case LoadingNextSet: {
            return { ...state, loadingNextSet: true };
        }
        case "events/newPostCreated": {
            const updated = { ...state };
            updated.posts.unshift(action.payload);
            return updated;
        }
        case "events/postDeleted": {
            return { ...state, posts: state.posts.filter(p => p.postId != action.payload.postId) }
        }
        case "events/postUpdated": {
            return { ...state, posts: state.posts.map(p => p.postId === action.payload.postId ? action.payload : p) }
        }
        case EventsReset: {
            return { ...state, eventsReset: true }
        }
        default: {
            return state;
        }
    }
}

const MaxPageCount = 5;
var previousProfileUserId: string | null = null;

const withPosts = (postsType: "profile" | "timeline") => (Component: any) => (childProps: any) => {
    const reduxDispatch = useAppDispatch()
    const postsEvent = useSelector((state: RootState) => state.events.postsEvent);
    const { scrollPercentage } = childProps;

    const [{ loading, posts, fetchedFromServer, continuationToken, loadingNextSet, page, eventsReset }, dispatch] = useReducer(postsReducer, {
        loading: true,
        posts: new Array<Post>(),
        fetchedFromServer: false,
        continuationToken: null,
        loadingNextSet: false,
        page: 0,
        eventsReset: false
    });

    const { profileUserId, isPersonalProfile } = childProps;

    const getData = async () => {
        const { ok, continuationToken: newContinueToken, result } = postsType === "timeline" ? await getTimelinePostsAsync(continuationToken, page) :
            await getPostsByUserAsync(profileUserId, continuationToken, page);

        if (ok) {
            dispatch({
                type: Completed,
                payload: {
                    posts: Array.from(result),
                    continueToken: newContinueToken
                }
            });
        }

        if (postsType === "profile" && profileUserId) {
            previousProfileUserId = `${profileUserId}`;
        }
    }

    if (postsType === "profile" && previousProfileUserId && profileUserId != previousProfileUserId) {
        previousProfileUserId = `${profileUserId}`;
        dispatch({ type: LoadingNewProfile });
    }

    useEffect(() => {
        reduxDispatch(clearEvents());
        dispatch({ type: EventsReset });
    }, []);

    useEffect(() => {
        if (!eventsReset) {
            return;
        }

        if (!fetchedFromServer) {
            dispatch({ type: Loading });
            getData();
        }

        if (postsEvent && (postsType === "timeline" || (postsType === "profile" && isPersonalProfile))) {
            dispatch({ type: postsEvent.type, payload: postsEvent.payload })
        }

        if (postsEvent) {
            reduxDispatch(clearEvents());
        }
    }, [postsEvent, eventsReset, fetchedFromServer]);

    useEffect(() => {
        if (scrollPercentage > 70 && continuationToken && !loadingNextSet && page < MaxPageCount) {
            dispatch({ type: LoadingNextSet });
            getData()
        }

    }, [scrollPercentage])

    const propsLocal = {
        loadingPosts: loading,
        posts
    }

    return <Component {...childProps} {...propsLocal}></Component>
}

export default withPosts;

