import React from "react"
import styled from "styled-components";

const Footer = () => {

    return (
        <FooterContainer>
            <p>Created by Najmuddin Dost</p>
            <p>.Net and React contractor</p>
            <p>Email: <a href="mailto:nnost@live.com">nnost@live.com</a></p>
        </FooterContainer>
    )
}

export default Footer;


const FooterContainer = styled.div`
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p{
        margin: 0;
    }

    & p:nth-child(2){
        margin-left: 40px;
        margin-right: 40px;
    }

    @media only screen and (max-width: 1024px) {
        &{
            flex-direction: column;
        }

        & p:nth-child(2){
            margin-top: 20px;
            margin-bottom: 20px;
            margin-left:auto;
            margin-right: auto;
        }
    }
`;