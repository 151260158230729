import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import '../../styles/n/nCancelIcon.scss';

interface Props {
    onClick: any;
}

const NCancelIcon = (props: Props) => {

    return (
        <div className="cancel-icon" onClick={props.onClick}>
            <ClearIcon />
        </div>
    )
}

export default NCancelIcon;