import React from "react"
import styled from "styled-components";


export default function BodyContent(){

    return(
        <BodyContainer>
            <BodyTitle>Why was I ever created?</BodyTitle>

            <BodyText>
                <span><i>Everyday I meditate upon this, and every night I groan<br />
                    Why is my own existence to myself the least known?</i><br /><br /></span>

                <span><i>Whence have I come, why this coming here?<br />
                    Where to must I go, when will my home to me be shown?</i><br /><br /></span>

                <span><i>Whatever is of the celestial realm, of that I speak<br />
                    I am ready to go, my clothes are packed to be away thrown.</i><br /><br /></span>

                <span><i>Joyous be that day that in search of the beloved<br />
                    I take wing towards that land, upon that air I am flown.</i><br /><br /></span>

                <span><i>Where is that ear that can hear my speech and song?<br />
                    Who is the one who puts voice in this mouth of dust and stone?</i><br /><br /></span >

                <span><i>I did not come here on my own accord, nor will I thus leave<br />
                    He who brought me here, shall return me to my very own.</i><br /><br /></span >

                <span><i>I am a bird of Paradise, I am not of the earthy realm<br />
                    For a few days imprisoned in my cage of flesh and bone.</i><br /><br /></span >

                <span><i>My soul is my guide, for my soul is of that abode<br />
                    I will not speak of the earthly, I am of the unknown.</i><br /><br /></span >
            </BodyText>

            <p><i>Classic poem by Rumi</i></p>
        </BodyContainer>
    )
}


const BodyContainer = styled.div`
    padding: 25px;
    width: 100%;
    justify-content: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fafafa;

    & p:first-of-type{
        font-size: 15px;
        color: #595959;

        & a{
            color: #595959;
        }
    }
`;

const BodyTitle = styled(BodyContainer)`
    font-size: 40px;
    font-weight: 600;
    line-height:  1.5;
`;

const BodyText = styled(BodyContainer)`
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    margin-bottom: 0;
    max-width: 800px;
    text-align: center;
    padding: 0;
`;