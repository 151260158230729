import { createSlice } from '@reduxjs/toolkit'

export interface Event {
    type: string,
    payload: any
}

interface EventsState {
    postsEvent: Event | null
}

const initialState: EventsState = {
    postsEvent: null
}

const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        newPostCreated(state, action) {
            state.postsEvent = {
                type: action.type,
                payload: { ...action.payload }
            };
        },
        postDeleted(state, action) {
            state.postsEvent = {
                type: action.type,
                payload: { ...action.payload }
            };
        },
        postUpdated(state, action) {
            state.postsEvent = {
                type: action.type,
                payload: { ...action.payload }
            };
        },
        clearEvents(state) {
            state.postsEvent = null;
        }
    }
});


const { newPostCreated, postDeleted, postUpdated, clearEvents } = eventsSlice.actions;

export default eventsSlice.reducer;
export { newPostCreated, postDeleted, postUpdated, clearEvents };