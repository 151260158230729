import React from "react"
import { Link } from "react-router-dom";
import styled from "styled-components";
import NButton from "../NComponents/NButton";
import { FaLinkedin } from 'react-icons/fa';


interface Props {
    onLogin: () => void
}

const Heading = (props: Props) => {

    return (
        <HeadingContainer >
            <NavBarWrapper >
                <div className="left-float">
                    <Link to="/">
                        <img id="logo" src="/logo.png" />
                        <span id="brand-name">Hikmasight</span>
                    </Link>
                    <span id="preview-text">(Preview)</span>
                </div>

                <div className="right-float">
                    <a className="link" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/najmuddin-dost/" >
                        <div className="icon">
                            <FaLinkedin />
                        </div>
                        <div className="label">LinkedIn</div>
                    </a>
                </div>
            </NavBarWrapper>

            <div className="title">Making life a meaningful spiritual adventure</div>
            <div className="subtitle">A platform dedicated to spreading the Truth in all areas of life and learning from the people of knowledge around the world.
            </div>

            <NButton onClick={props.onLogin} id="login-btn" mode="primary" text="Login" />
        </HeadingContainer>
    )
}

export default Heading;


const HeadingContainer = styled.div`
    padding: 40px;
    height: 100%;
    width: 100%;
    width: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    .title{
        margin: auto;
        width: 800px;
        font-family: ${props => props.theme.fonts.main};
        font-size: 65px;
        font-weight: 500;
        margin-bottom: 20px;
        margin-top: 100px;

        background-image: linear-gradient(81.84deg, #0099ff -9.4%, #a033ff 51.57%, #ff5280 84.07%, #ff7061 90.59%);
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .subtitle{
        font-family: ${props => props.theme.fonts.main};
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        width: 600px;
        margin-top: 15px;
    }

    @media only screen and (max-width: 1024px) {
        .title{
            width: auto;
            font-size: 45px;
        }
        .subtitle{
            width: auto;
        }
    }
`;

const NavBarWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    .left-float{
        #logo{
            width: 40px;
            height: 40px;
            cursor: pointer;
        }

        #brand-name{
            color: ${props => props.theme.colors.text};
            font-weight: 600;
            font-size: 16px;
            line-height: 48px;
            -webkit-font-smoothing: antialiased;
            margin-left: 10px;
        }

        #preview-text{
            font-size: 13px;
            color: black;
            font-weight: 400;
            padding-left: 5px;
        }
    }

    .right-float{
        .link{
            color: ${props => props.theme.colors.text};
            font-weight: 500;
            text-align: center;
            align-items: baseline;
            display: flex;
            font-size: 16px;
            -webkit-font-smoothing: antialiased;
            font-weight: 500;
            line-height: 50px;
            transition: 0.2s ease-in-out;

            &:hover{
                color: ${props => props.theme.colors.primaryDark};
            }

            .icon{
                align-self: center;
                display: flex;
                margin-top: 2px;
            }

            .label{
                padding-left: 5px;
            }
        }
    }
`