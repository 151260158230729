import { ContinuationTokenHeader } from '../Constants';
import { Comment, CreateCommentRequest, UpdateCommentLikeRequest } from '../Types';
import { ApiResponse, callApiWithOkAsync, callApiWithPaginatedResponseAsync, callApiWithResponseAsync } from './commonServices';

export async function updateCommentLikeAsync(payload: UpdateCommentLikeRequest, keepalive = false): Promise<void> {

    await callApiWithOkAsync('updateCommentLike', `/api/v1/comments/like`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload),
        keepalive: keepalive
    });
}

export async function getPostCommentsAsync(postId: string, continuationToken: string | null, page: number): Promise<ApiResponse> {
    return await callApiWithPaginatedResponseAsync(`getPostComments, Page: ${page}`, `/api/v1/comments/${postId}`, {
        method: 'GET',
        headers: continuationToken ? { [ContinuationTokenHeader]: continuationToken } : {}
    }, false);
}


export async function createCommentAsync(payload: CreateCommentRequest): Promise<Comment> {

    return await callApiWithResponseAsync("createComment", "/api/v1/comments", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });
}

export async function deleteCommentAsync(commentId: string): Promise<boolean> {
    return await callApiWithOkAsync("deleteComment", `/api/v1/comments/${commentId}`, {
        method: 'DELETE'
    });
}