import { AccessToken } from '../auth/AuthenticatedUser';
import { ContinuationTokenHeader } from '../Constants';

const isDevelopment: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const ServerUrl = isDevelopment ? process.env.REACT_APP_BACKEND_API : "";

export interface ApiResponse {
    ok: boolean,
    code?: number,
    result: any,
    continuationToken: string | null
    error?: any
}

export async function callApiWithOkAsync(name: string, path: string, config: any = {}): Promise<boolean> {
    try {
        config.headers = config.headers || {};
        config.headers['Authorization'] = "Bearer " + AccessToken;

        const response = await fetch(`${ServerUrl}${path}`, config);
        console.log(`\nName: ${name}, Path: ${path}, Ok: ${response.ok}, Status: ${response.status}`);
        return response.ok;
    } catch (error) {
        console.error(`Error in ${name} api call => Url: ${ServerUrl}${path}, error: ${error}`);
    }
    return false;
}

export async function callApiWithResponseAsync(name: string, path: string, config: any = {}, log = true): Promise<any> {
    try {
        config.headers = config.headers || {};
        config.headers['Authorization'] = "Bearer " + AccessToken;

        const response = await fetch(`${ServerUrl}${path}`, config);
        const result = await response.json();

        if (log) {
            console.log(`\nName: ${name}, Path: ${path}, Ok: ${response.ok}, Status: ${response.status}`);
            console.log(result);
        }
        if (response.ok) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error(`Error in ${name} api call => Url: ${ServerUrl}${path}, error: ${error}`);
        return null;
    }
}

export async function callApiWithPaginatedResponseAsync(name: string, path: string, config: any = {}, log = true): Promise<ApiResponse> {
    try {
        config.headers = config.headers || {};
        config.headers['Authorization'] = "Bearer " + AccessToken;

        const response = await fetch(`${ServerUrl}${path}`, config);
        const result = await response.json();

        const apiResponse = {
            ok: response.ok,
            code: response.status,
            result,
            continuationToken: response.headers.get(ContinuationTokenHeader)
        };

        if (log) {
            console.log(`\nName: ${name}, Path: ${path}, Ok: ${response.ok}, Status: ${response.status}`);
            console.log(apiResponse);
        }

        return apiResponse;
    } catch (error) {
        console.error(`Error in ${name} api call => Url: ${ServerUrl}${path}, error: ${error}`);
        return {
            ok: false,
            error: error,
            continuationToken: null,
            result: null
        };
    }
}