import React from "react";
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ImageIcon from '@material-ui/icons/Image';
import {
    hideToastAction, showInfoToastAction,
    showErrorToastAction, showSuccessToastAction, IAction, UpdateProfileAction, submittingAction
} from '../../redux/actions'
import { connect } from 'react-redux';
import Constants from '../../Constants';
import "@pathofdev/react-tag-input/build/index.css";
import { updateUserProfileAsync } from '../../services/profileService';
import EditIcon from '@material-ui/icons/Edit';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import Cropper from 'react-easy-crop';
import getCroppedImg, { urltoFile } from '../UserProfile/CropImage';
import { Profile, UpdateProfileRequest, UserInfo } from "../../Types";
import { Area, Point } from "react-easy-crop/types";
import { compare, ResembleSingleCallbackComparisonResult } from "resemblejs";
import '../../styles/dialog.scss';
import NCancelIcon from "../NComponents/NCancelIcon";
import NButton from "../NComponents/NButton";


interface EditProfileDialogProps extends WithAuth0Props {
    data: Profile,
    viewerUserId: string,
    open: boolean,
    handleClose: () => void,
    hideToastAction: () => void,
    submittingAction: () => void,
    showErrorToastAction: (payload: any) => IAction,
    showInfoToastAction: (payload: any) => IAction,
    showSuccessToastAction: (payload: any) => IAction,
    UpdateProfileAction: (userInfo: UserInfo) => IAction
}

type EditProfileDialogState = {
    imageFile: any,
    disablePostButton: boolean,
    name: string,
    title: string,
    crop: any,
    zoom: any,
    aspect: any,
    InitialCroppedAreaPixels: any,
    initialSet: boolean,
    croppedAreaPixels: any,
    originalFileType: string
}

class EditProfileDialog extends React.Component<EditProfileDialogProps, EditProfileDialogState> {
    constructor(props: EditProfileDialogProps) {
        super(props);

        this.state = {
            imageFile: props.data.picture,
            disablePostButton: false,
            name: props.data.name,
            title: props.data.title,
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 1,
            InitialCroppedAreaPixels: null,
            initialSet: false,
            croppedAreaPixels: null,
            originalFileType: 'image/' + this.props.data.picture.substring(this.props.data.picture.lastIndexOf('.') + 1)
        }
    }

    onCropChange = (crop: Point) => {
        this.setState({ crop })
    }

    onCropComplete = async (croppedArea: Area, croppedAreaPixels: Area) => {
        if (!this.state.initialSet) {
            this.setState({ croppedAreaPixels, InitialCroppedAreaPixels: croppedAreaPixels, initialSet: true });
        } else {
            this.setState({ croppedAreaPixels });
        }
    }

    onZoomChange = (zoom: number) => {
        this.setState({ zoom })
    }

    handleFileInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from((event.target as any).files);
        if (files.length > 1) {
            this.props.showErrorToastAction("Error! Only 1 image can be uploaded.");
            return;
        }

        const file: any = files[0];
        const allowedTypes = ['image/png', 'image/jpeg'];

        if (allowedTypes.every(type => file.type !== type)) {
            this.props.showErrorToastAction(`Error! file type not supported, please select a png or jpg image.`);
            return;
        }

        if (file.size > (Constants.MAX_IMAGE_FILE_SIZE_MB * 1024 * 1024)) {
            this.props.showErrorToastAction(`'${file.name}' is too large, please pick a smaller file. Max size is ${Constants.MAX_IMAGE_FILE_SIZE_MB}mb`);
            return;
        }

        let imageDataUrl = await this.readFile(file)

        this.setState({
            imageFile: imageDataUrl,
            originalFileType: file.type
        });
    }

    readFile = (file: Blob) => {
        return new Promise(resolve => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        });
    }

    onPost = async () => {
        if (this.state.disablePostButton) {
            document.getElementById("name-field")?.focus();
            return;
        }

        this.props.handleClose();
        let imageUpdateable = !this.state.imageFile.includes("gravatar");

        let cropChanged = imageUpdateable;
        let file: File | null = null;

        if (!imageUpdateable) {
            await this.updateProfile(cropChanged, file);
            return;
        }

        this.props.submittingAction();

        const originalCrop = await getCroppedImg(
            this.state.imageFile,
            this.state.InitialCroppedAreaPixels,
            this.state.originalFileType
        )

        const currentCrop = await getCroppedImg(
            this.state.imageFile,
            this.state.croppedAreaPixels,
            this.state.originalFileType
        )

        try {
            compare(currentCrop, originalCrop, {}, async (err: unknown, data: ResembleSingleCallbackComparisonResult) => {
                if (!err) {
                    cropChanged = Number(data.misMatchPercentage) != 0;
                    file = await urltoFile(currentCrop, this.state.originalFileType);
                    await this.updateProfile(cropChanged, file);
                } else {
                    await this.updateProfile(cropChanged, file);
                }
            });
        } catch (error) {
            this.props.hideToastAction();
            console.log(error);
            return;
        }
    }

    updateProfile = async (cropChanged: boolean, file: any) => {
        const userId = this.props.auth0.user!.sub!;
        if (userId != this.props.viewerUserId) {
            return;
        }

        if (this.state.name.length === 0) {
            this.props.showErrorToastAction("Empty name not allowed");
            return;
        } else if (!this.state.imageFile) {
            this.props.showErrorToastAction("Profile picture is mandatory");
            return;
        }

        let changed = cropChanged || this.state.imageFile != this.props.data.picture || this.state.name != this.props.data.name || this.state.title != this.props.data.title;

        if (changed) {
            let postData: UpdateProfileRequest = {
                userId,
                imageFile: file,
                imageChanged: this.state.imageFile != this.props.data.picture || cropChanged,
                existingPictureUrl: this.props.data.picture,
                name: this.state.name.trim(),
                title: this.state.title ? this.state.title.trim() : this.state.title
            }

            let updatedProfile = await updateUserProfileAsync(postData);
            this.props.hideToastAction();

            if (updatedProfile) {
                this.props.UpdateProfileAction({
                    name: updatedProfile.name,
                    picture: updatedProfile.picture,
                    title: updatedProfile.title,
                    userId
                });
                this.props.showSuccessToastAction("Profile updated.");
            } else {
                this.props.showErrorToastAction("Failed to update your profile.");
            }
        }else{
            this.props.hideToastAction();
        }
    }

    onNameChange = (e: React.ChangeEvent) => {
        this.setState({ name: (e.target as any).value, disablePostButton: (e.target as any).value.trim().length === 0 });
    }

    onTitleChange = (e: React.ChangeEvent) => {
        this.setState({ title: (e.target as any).value });
    }


    render() {
        if (this.props.auth0.user!.sub != this.props.viewerUserId) {
            return null;
        }

        let isGravatar = this.state.imageFile.includes("gravatar");

        return (
            <div >
                <Dialog onClose={this.props.handleClose} open={this.props.open}>
                    <div className="dialog-header">
                        <div className="dialog-title">Update profile</div>
                        <div className="dialog-cancel"><NCancelIcon onClick={this.props.handleClose} /></div>
                    </div>

                    <DialogContent >

                        <div className="dialog-body">
                            <div className="edit-profile-picture">
                                {!isGravatar && this.state.imageFile ? <Cropper
                                    image={this.state.imageFile}
                                    crop={this.state.crop}
                                    zoom={this.state.zoom}
                                    aspect={this.state.aspect}
                                    onCropChange={this.onCropChange}
                                    onCropComplete={this.onCropComplete}
                                    onZoomChange={this.onZoomChange}
                                />
                                    :
                                    <div className="no-image">
                                        <div>Please select a profile picture</div>
                                        <div>
                                            <Button onClick={() => (document.getElementById('upload-image-input')! as HTMLInputElement).click()} startIcon={<ImageIcon />}>Upload</Button>
                                        </div>
                                    </div>
                                }

                                {!isGravatar && <div className="edit-button-wrapper">
                                    <IconButton onClick={() => (document.getElementById('upload-image-input')! as HTMLInputElement).click()} ><EditIcon /></IconButton>
                                </div>}
                            </div>

                            <TextField inputProps={{ maxLength: Constants.MAX_PROFILE_NAME_LENGTH }} id="name-field" onChange={this.onNameChange} value={this.state.name} fullWidth required label="Name" />
                            <TextField inputProps={{ maxLength: Constants.MAX_PROFILE_TITLE_LENGTH }} id="title-field" onChange={this.onTitleChange} value={this.state.title || ""} fullWidth label="Title" />
                        </div>

                    </DialogContent>

                    <div className="dialog-footer">
                        <NButton style={{ width: "80px" }} mode="secondary" size="small" onClick={this.props.handleClose} text="Cancel" />
                        <NButton style={{ width: "80px" }} mode="primary" size="small" onClick={this.onPost} text="Save" />
                        <input id="upload-image-input" type="file" onChange={this.handleFileInput} accept="image/png,image/jpeg" hidden />
                    </div>
                </Dialog>

            </div >
        );
    }
}

export default connect(null, {
    hideToastAction,
    submittingAction,
    showErrorToastAction,
    showInfoToastAction,
    showSuccessToastAction,
    UpdateProfileAction
})(withAuth0(EditProfileDialog))


const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
