import React, { Component } from "react";
import EditProfileDialog from "../CustomDialogs/EditProfileDialog";
import { followUserAsync } from '../../services/profileService'
import { Profile } from "../../Types";
import NButton from "../NComponents/NButton";
import { handleGenericCounter } from "../../services/Helpers";

type ProfileHeadingProps = {
    isPersonalAccount: boolean,
    profile: Profile,
    viewerUserId: string,
    profileUserId: string
}

type ProfileHeadingState = {
    openEditDialog: boolean,
    followUser: boolean,
    followersCount: number
}

class ProfileHeading extends Component<ProfileHeadingProps, ProfileHeadingState> {
    constructor(props: ProfileHeadingProps) {
        super(props);

        this.state = {
            openEditDialog: false,
            followUser: this.props.profile.isFollowing,
            followersCount: this.props.profile.followersCount
        }
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.handleBeforeUnload, true);
    }

    handleBeforeUnload = async () => {
        await this.processFollowing(true);
    }

    async componentWillUnmount() {
        window.removeEventListener("beforeunload", this.handleBeforeUnload, true);
        await this.processFollowing();
    }

    onEdit = () => {
        this.setState({ openEditDialog: true });
    }

    onEditDialogClose = () => {
        this.setState({ openEditDialog: false });
    }

    processFollowing = async (keepalive?: boolean) => {
        let updateFollowing = this.props.profile.isFollowing != this.state.followUser;

        if (updateFollowing) {
            let payload = {
                from: this.props.viewerUserId,
                target: this.props.profileUserId,
                follow: this.state.followUser
            }

            await followUserAsync(payload, keepalive);
        }

    }

    handleFollowing = () => {
        var updated = handleGenericCounter(this.props.profile.isFollowing, this.state.followUser, this.props.profile.followersCount, this.state.followersCount);
        this.setState({ followUser: updated.newBool, followersCount: updated.newCount });
    }

    render() {
        const profile = this.props.profile;

        return (
            <div className="heading-wrapper">
                <div className="heading-container">

                    <div className="picture-wrapper">
                        <img src={profile.picture} className="profile-picture"></img>
                    </div>

                    <div className="profile-titles">
                        <div className="primary">{profile.name}</div>
                        <div className="secondary">{profile.title || ""}</div>

                        <div className="profile-stats-container">

                            <div className="stats">
                                <div className="stats-figure">{profile.postsCount}</div>
                                <div className="stats-name">Posts</div>
                            </div>

                            <div className="vertical-line" />
                            <div className="stats">
                                <div className="stats-figure">{this.state.followersCount}</div>
                                <div className="stats-name">Followers</div>
                            </div>

                            <div className="vertical-line" />
                            <div className="stats">
                                <div className="stats-figure">{profile.followingCount}</div>
                                <div className="stats-name">Following</div>
                            </div>

                        </div>

                    </div>

                    <div className="right-float">
                        {this.props.isPersonalAccount ?
                            <NButton onClick={this.onEdit} mode="secondary" text="Edit"/>
                            :
                            <NButton onClick={this.handleFollowing} mode={this.state.followUser ? "primary" : "secondary"} text={this.state.followUser ? "Following" : "Follow"} />
                        }
                    </div>

                </div>

                {this.state.openEditDialog &&
                    <EditProfileDialog
                        data={profile}
                        viewerUserId={this.props.viewerUserId}
                        open={this.state.openEditDialog}
                        handleClose={this.onEditDialogClose} />}

            </div>

        );
    }
}

export default ProfileHeading;