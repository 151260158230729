import React from 'react';
import '../../styles/n/nButton.scss';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    mode: "primary" | "secondary",
    text: string,
    size?: "small" | "standard"
}

const NButton = (props: Props) => {

    const { mode, size, text, ...rest } = props;

    return (
        <button {...rest} className={`nbutton ${mode} ${size}`}   >
            {text }
        </button>
    )
}


interface NIconButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    mode: "primary" | "secondary",
    text?: string,
    size?: "small" | "standard"
    icon: any
}

const NIconButton = (props: NIconButtonProps) => {

    const { mode, size, icon, text, ...rest } = props;

    return (
        <button {...rest} className={`nbutton icon ${mode} ${size}`}>
            <div className="button-content">
                {icon}
                <div>
                    {text}
                </div>
            </div>
        </button>
    )
}

export default NButton;
export { NIconButton };