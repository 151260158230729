import React, { useEffect, useState } from 'react';
import '../../styles/home.scss';
import TimelinePost from '../TimelinePost';
import { useAuth0 } from '@auth0/auth0-react';
import TimelineSkeleton from '../TimelinePost/TimelineSkeleton';
import { Post } from '../../Types';
import { getSinglePostAsync } from '../../services/postsService';
import { useParams } from 'react-router';
import { useAppSelector } from '../../redux/store';

interface RouteParamTypes {
    postId: string
}

function SingleThread() {
    const { user } = useAuth0();
    const postsEvent = useAppSelector((state) => state.events.postsEvent);

    const { postId } = useParams<RouteParamTypes>();
    const [post, setPost] = useState(null as (null | Post));
    const [loading, setLoading] = useState(true);

    const getData = async () => {
        const data = await getSinglePostAsync(postId);
        if (data) {
            setPost(data);
        }
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (postsEvent && postsEvent.payload.postId === postId) {
            switch (postsEvent.type) {
                case "events/postDeleted": {
                    setPost(null);
                    break;
                }
                case "events/postUpdated": {
                    setPost(postsEvent.payload);
                    break;
                }
            }
        }

    }, [postsEvent]);


    return (
        <div className="home-main-container">
            <div className="timeline-scroll">

                {loading ?
                    (<div><TimelineSkeleton /></div>) :
                    !post ? <div className="empty-timeline">Thread does not exist.</div> :
                        <TimelinePost auth0Sub={user!.sub!} data={post} />
                }
            </div>
        </div >
    );
}

export default SingleThread;