import { SearchRequest, SearchResult } from '../Types';
import { callApiWithResponseAsync } from './commonServices';

export const SearchCache: { [name: string]: SearchResult } = {};

export async function searchAsync(payload: SearchRequest): Promise<SearchResult | null> {

    if (!payload.value || payload.value == null || payload.value.trim() == "") {
        return null;
    }

    let key = `${payload.value}.${payload.page}.${payload.max}`;

    if (SearchCache[key]) {
        return { ...SearchCache[key] };
    }

    const result: SearchResult = await callApiWithResponseAsync(`search: ${payload.value}`, "/api/v1/search", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });

    SearchCache[key] = result;
    return result;
}