import React from "react";
import { useHistory } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { UserInfo } from "../../Types";

type Props = {
    data: UserInfo,
    final: boolean
}

const UserSearchItem = (props: Props) => {

    const data = props.data;
    const history = useHistory();

    const loadProfile = (e: React.MouseEvent) => {
        history.push(`/profile/${data.userId}`);
    }

    return (
        <div>
            <div className="user-search-card-container">

                <div>
                    <img onClick={loadProfile} src={data.picture} className="user-profile-picture"></img>
                </div>

                <div className="profile-titles">
                    <div onClick={loadProfile} className="name">{data.name}</div>
                    <div className="title">{data.title || ""}</div>
                </div>

                <div className="right-float">
                    <IconButton onClick={loadProfile}  ><AccountCircleIcon /></IconButton>
                </div>

            </div>
            {!props.final && <hr />}
        </div>
    )
}

export default UserSearchItem;
