import { IAction } from '../actions';
import { HideToast, ShowErrorToast, ShowInfoToast, ShowSuccessToast, ShowWarningToast, Submitting } from '../actionTypes';

export type ToastState = {
    show: boolean,
    severity: "success" | "error" | "warning" | "info" | undefined,
    message: string,
    submitting: boolean
}

const toastState: ToastState = {
    show: false,
    severity: undefined,
    message: "",
    submitting: false
}

export default function toastReducer(state = toastState, action: IAction): ToastState {

    switch (action.type) {
        case ShowSuccessToast: {
            return { ...state, severity: "success", show: true, message: action.payload, submitting: false };
        }
        case ShowErrorToast: {
            return { ...state, severity: "error", show: true, message: action.payload, submitting: false };
        }
        case ShowWarningToast: {
            return { ...state, severity: "warning", show: true, message: action.payload, submitting: false };
        }
        case ShowInfoToast: {
            return { ...state, severity: "info", show: true, message: action.payload, submitting: false };
        }
        case HideToast: {
            return { ...state, show: false, submitting: false };
        }
        case Submitting: {
            return { ...state, severity: "info", show: true, message: "Submitting...", submitting: true };
        }
    }

    return state;
}

