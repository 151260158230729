const Constants = {
    MAX_TIMELINE_BODY_CHARS: 300,
    MAX_TIMELINE_BODY_INITIAL_LINES: 5,
    MAX_POST_TAGS: 5,
    MAX_POST_CONTENT_CHARS: 5000,
    MAX_IMAGE_FILE_SIZE_MB: 8,
    MAX_PROFILE_NAME_LENGTH: 50,
    MAX_PROFILE_TITLE_LENGTH: 100,
    MAX_PROFILE_ABOUT_LENGTH: 2000,
    MAX_PROFILE_EDUCATION_LENGTH: 200,
    SESSION_STORAGE_PROFILE_USER_ID: "profileUserId"
}

export default Constants;

const ContinuationTokenHeader = "x-ms-continuation";

export { ContinuationTokenHeader }