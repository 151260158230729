import React from 'react';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import CommentItem from './Comments/CommentItem';
import '../../styles/postFooter.scss';
import { useSelector } from 'react-redux';
import { Post } from '../../Types';
import { RootState } from '../../redux/store';
import PostButtons from './PostButtons';
import useComments from '../Hooks/useComments';
import NButton from '../NComponents/NButton';

type Props = {
    data: Post,
    auth0Sub: string
}

function PostComments(props: Props) {
    const userProfilePicture = useSelector((state: RootState) => state.profile.picture);
    const { data, auth0Sub } = props;
    const { postId, totalCommentsCount } = props.data;

    const [
        state,
        showMore,
        commentsToShow,
        onShowMoreComments,
        onCancelReply,
        onCommentDeleted,
        onReply,
        onCommentSubmit,
        onCommentChange
    ] = useComments(postId, totalCommentsCount);

    const { loading, userComment, replyingTo, numberOfCommentsToShow, comments, commentsCount } = state;

    return (
        <>
            <PostButtons data={data} auth0Sub={auth0Sub} commentsCount={commentsCount} />

            <div className="comments-container">

                {showMore > 0 &&
                    <div className="show-more" onClick={onShowMoreComments}>
                        Show {showMore} previous comments
                </div>}

                {comments.length != 0 &&
                    <div>
                        <div className="public-comments">
                            {commentsToShow.map((c) => <CommentItem key={c.commentId} onCommentDeleted={onCommentDeleted} comment={c} onReply={onReply} />)}
                        </div>
                        <hr />
                    </div>
                }

                <div>
                    {replyingTo &&
                        <div className="reply-wrapper">
                            <div className="cancel-reply" onClick={onCancelReply}><ClearIcon /></div>
                            <div>
                                <span className="info">Replying to</span>
                                <span className="name">{replyingTo.name}</span>
                            </div>
                        </div>}

                    <div className={`user-comment ${postId}`}>
                        <img className="profile-pic" alt="" src={userProfilePicture} />

                        <div className="textfield-wrapper">
                            <TextField name="textfield" onChange={onCommentChange} value={userComment} multiline fullWidth placeholder="Leave a comment..." />
                        </div>

                        <div className="submit-btn">
                            {userComment.trim().length > 0 && <NButton mode="primary" onClick={onCommentSubmit} disabled={userComment.trim().length === 0} size="small" text="Post" />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PostComments;