import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Profile } from '../../Types';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserProfileAsync } from '../../services/profileService';
import { IAction } from '../../redux/actions';
import { Completed, Loading, LoadingNewProfile, UpdateFromRedux } from '../../redux/actionTypes';
import { useParams } from 'react-router-dom';


interface ExtraProps {
    loading: boolean,
    profile: null | Profile,
    fetchedFromServer: boolean
}

interface RouteParamTypes {
    userId: string
}

const profileReducer = (state: ExtraProps, action: IAction): ExtraProps => {
    switch (action.type) {
        case Loading: {
            return { ...state, loading: true, fetchedFromServer: true };
        }
        case LoadingNewProfile: {
            return { ...state, loading: true, fetchedFromServer: false, profile: null };
        }
        case Completed: {
            return { ...state, loading: false, profile: action.payload, fetchedFromServer: true };
        }
        case UpdateFromRedux: {
            const { name, title, picture } = action.payload;
            return { ...state, profile: { ...state.profile, name, title, picture } as Profile };
        }
        default: {
            return state;
        }
    }
}

var previousProfileUserId: string | null = null;

const withProfile = () => (Component: any) => (childProps: any) => {

    const { user, isAuthenticated } = useAuth0();
    const profileInRedux = useSelector((state: RootState) => state.profile);

    const { userId } = useParams<RouteParamTypes>();
    const profileUserId: string = userId ? userId : user!.sub!;
    const isPersonalProfile = profileUserId === user!.sub;

    const [{ loading, profile, fetchedFromServer }, dispatch] = useReducer(profileReducer, {
        loading: true,
        profile: null,
        fetchedFromServer: false
    });

    const getProfile = async () => {
        let data = await getUserProfileAsync(profileUserId);
        if (!data) {
            return;
        }

        if (data) {
            dispatch({ type: Completed, payload: data });
        }

        if (profileUserId) {
            previousProfileUserId = `${profileUserId}`;
        }
    }

    if (previousProfileUserId && profileUserId != previousProfileUserId) {
        previousProfileUserId = `${profileUserId}`;
        dispatch({ type: LoadingNewProfile });
    }

    useEffect(() => {
        if (!fetchedFromServer) {
            dispatch({ type: Loading });
            getProfile();
        } else if (profileInRedux) {
            dispatch({ type: UpdateFromRedux, payload: profileInRedux });
        }
    }, [profileInRedux, fetchedFromServer]);

    const hocLocal = {
        loadingProfile: loading,
        profile,
        isAuthenticated,
        isPersonalProfile,
        viewerUserId: user!.sub,
        profileUserId
    }

    return <Component {...childProps} {...hocLocal} />
}

export default withProfile;

