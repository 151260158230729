import React from 'react';
import { connect } from 'react-redux';
import Constants from '../../Constants';
import { Post } from '../../Types';
import ImageDialog from '../CustomDialogs/ImageDialog';

type Props = {
    data: Post
}

type State = {
    cutBodyText: boolean,
    cutBodyLines: boolean,
    imageDialogOpen: boolean
}

class PostBody extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            cutBodyText: props.data.content.length > Constants.MAX_TIMELINE_BODY_CHARS,
            cutBodyLines: props.data.content.split(/\r\n|\r|\n/).length > Constants.MAX_TIMELINE_BODY_INITIAL_LINES,
            imageDialogOpen: false
        };
    }

    onReadMore = () => {
        this.setState({ cutBodyText: false, cutBodyLines: false });
    }

    onTagClicked = (tag: string) => {
        alert("coming soon :)");
    }

    onImageDialogOpen = () => {
        this.setState({ imageDialogOpen: true });
    }

    onImageDialogClose = () => {
        this.setState({ imageDialogOpen: false });
    }

    render() {
        let bodytext = this.props.data.content;

        if (this.state.cutBodyLines) {
            bodytext = bodytext.split(/\r\n|\r|\n/).slice(0, Constants.MAX_TIMELINE_BODY_INITIAL_LINES).join("\n");
        } else if (this.state.cutBodyText) {
            bodytext = bodytext.substring(0, Constants.MAX_TIMELINE_BODY_CHARS);
        }

        return (
            <div className="post-body">
                {this.props.data.imageUrl &&
                    <div className="post-img-container" onClick={this.onImageDialogOpen}>
                        <img className="post-img" alt="" src={this.props.data.imageUrl}></img>
                    </div>}

                <div className="post-bodytext-container">
                    <span>{bodytext}</span>
                    {(this.state.cutBodyText || this.state.cutBodyLines) && (<span onClick={this.onReadMore} className="post-read-more">... Read more</span>)}
                </div>

                {this.props.data.tags && <div className="post-tags-container">
                    {this.props.data.tags.map((tag, index) => {
                        return <span onClick={() => this.onTagClicked(tag)} className="post-tag" key={index}>{tag}</span>
                    })}
                </div>}

                {this.state.imageDialogOpen && <ImageDialog imageSrc={this.props.data.imageUrl} open={this.state.imageDialogOpen} handleClose={this.onImageDialogClose} />}
            </div>
        );
    }
}

export default connect(null, {})(PostBody);