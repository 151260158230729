import React from "react";
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import ImageIcon from '@material-ui/icons/Image';
import { hideToastAction, showInfoToastAction, showErrorToastAction, showSuccessToastAction, submittingAction, IAction } from '../../redux/actions'
import { connect } from 'react-redux';
import Constants from '../../Constants';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { createNewPostAsync, updatePostAsync } from '../../services/postsService';
import { isValidHttpUrl } from '../../services/Helpers';
import { Post } from "../../Types";
import { RootState } from "../../redux/store";
import { newPostCreated, postUpdated } from '../../redux/reducers/eventsReducer';
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import NCancelIcon from "../NComponents/NCancelIcon";
import '../../styles/dialog.scss';
import NButton, { NIconButton } from "../NComponents/NButton";


type PostDialogProps = {
    handleClose: () => void,
    mode: "create" | "edit",
    showErrorToastAction: (message: string) => IAction,
    showInfoToastAction: (message: string) => IAction,
    showSuccessToastAction: (message: string) => IAction,
    submittingAction: () => IAction,
    hideToastAction: () => IAction,
    newPostCreated: ActionCreatorWithPayload<any, string>,
    postUpdated: ActionCreatorWithPayload<any, string>,
    userId: string,
    data?: Post,
    open: boolean
}

type PostDialogState = {
    content: string
    imageFile: any,
    tags: string[],
    disablePostButton: boolean
}

class PostDialog extends React.Component<PostDialogProps, PostDialogState> {
    constructor(props: PostDialogProps) {
        super(props);

        if (this.props.mode === "create") {
            this.state = {
                content: "",
                imageFile: null,
                tags: [],
                disablePostButton: true
            }
        } else if (this.props.mode === "edit") {
            this.state = {
                content: this.props.data!.content,
                imageFile: this.props.data!.imageUrl,
                tags: !this.props.data!.tags ? [] : this.props.data!.tags,
                disablePostButton: false
            }
        }
    }

    handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from((event.target as any).files);
        if (files.length > 1) {
            this.props.showErrorToastAction("Error! Only 1 image can be uploaded.");
            return;
        }

        const file = files[0] as any;
        const allowedTypes = ['image/png', 'image/jpeg'];

        if (allowedTypes.every(type => file.type !== type)) {
            this.props.showErrorToastAction(`Error! file type not supported, please select a png or jpg image.`);
            return;
        }

        if (file.size > (Constants.MAX_IMAGE_FILE_SIZE_MB * 1024 * 1024)) {
            this.props.showErrorToastAction(`'${file.name}' is too large, please pick a smaller file. Max size is ${Constants.MAX_IMAGE_FILE_SIZE_MB}mb`);
            return;
        }

        this.setState({
            imageFile: (event.target as any).files[0]
        });
    }

    deleteImage = () => {
        this.setState({ imageFile: null });
    }

    onPost = async () => {
        if (this.state.disablePostButton) {
            document.getElementById("post-content-input")?.focus();
            return;
        }

        this.props.handleClose();
        let bodyText = this.state.content.trim();

        if (bodyText.length === 0) {
            this.props.showErrorToastAction("Empty post body not allowed");
        }

        if (this.props.mode === "create") {
            let newPost = {
                content: bodyText,
                tags: this.state.tags,
                file: this.state.imageFile
            }

            this.props.submittingAction();
            let response = await createNewPostAsync(newPost);
            this.props.hideToastAction();

            if (response) {
                this.props.showSuccessToastAction("New post created.");
                this.props.newPostCreated(response);
            } else {
                this.props.showErrorToastAction("Failed to create your new post.");
            }
        } else if (this.props.mode === "edit" && this.props.userId === this.props.data!.userId) {
            let changed = this.props.data!.content != bodyText || this.state.tags != this.props.data!.tags || this.state.imageFile != this.props.data!.imageUrl;

            if (!changed) {
                return;
            }

            let updatePost = {
                postId: this.props.data!.postId,
                content: bodyText,
                tags: this.state.tags,
                imageFile: this.state.imageFile,
                imageChanged: this.state.imageFile != this.props.data!.imageUrl
            }

            this.props.submittingAction();
            let updatedPost = await updatePostAsync(updatePost);
            this.props.hideToastAction();

            if (updatePost) {
                this.props.postUpdated(updatedPost);
                this.props.showInfoToastAction("Post updated.");
            } else {
                this.props.showErrorToastAction("Failed to update post.");
            }
        }
    }

    onTagsChange = (newTags: string[]) => {
        newTags = newTags.map((t) => t.startsWith("#") ? t : "#" + t);
        this.setState({ tags: newTags });
    }

    onBodyInput = (e: React.ChangeEvent) => {
        if ((e.target as any).value.length <= Constants.MAX_POST_CONTENT_CHARS) {
            this.setState({ content: (e.target as any).value, disablePostButton: (e.target as any).value.trim().length === 0 });
        }
    }

    render() {
        let title = this.props.mode === "edit" ? "Edit your post" : "Write a post";
        let submitText = this.props.mode === "edit" ? "Save" : "Post";

        return (
            <div>
                <Dialog onClose={this.props.handleClose} open={this.props.open}>
                    <div className="dialog-header">
                        <div className="dialog-title">{title}</div>
                        <div className="dialog-cancel"><NCancelIcon onClick={this.props.handleClose} /></div>
                    </div>

                    <DialogContent >

                        <div className="dialog-body">

                            <div className="post-body">

                                <div className="post-body-field">
                                    <TextField onChange={this.onBodyInput} value={this.state.content} id="post-content-input" autoFocus multiline fullWidth required placeholder="Whats on your mind?" />
                                </div>

                                {this.state.imageFile &&
                                    <div className='image-preview-container'>
                                        <div style={{ position: "relative" }}>
                                            <NCancelIcon onClick={this.deleteImage} />

                                            <img className='image-preview' src={isValidHttpUrl(this.state.imageFile) ? this.state.imageFile : URL.createObjectURL(this.state.imageFile)} alt='' />
                                        </div>
                                    </div>
                                }

                                <ReactTagInput
                                    placeholder="Tags (Press Enter)"
                                    maxTags={Constants.MAX_POST_TAGS}
                                    editable={false}
                                    removeOnBackspace={true}
                                    tags={this.state.tags}
                                    onChange={this.onTagsChange}
                                />
                            </div>

                        </div>

                    </DialogContent>

                    <div className="dialog-footer space-between">
                        <NIconButton mode="primary" icon={<ImageIcon />} size="small" onClick={() => document.getElementById('upload-image-input')!.click()} />

                        <div>
                            <NButton style={{ width: "80px" }} mode="secondary" size="small" onClick={this.props.handleClose} text="Cancel" />
                            <NButton style={{ width: "80px" }} mode="primary" size="small" onClick={this.onPost} text={submitText} />
                        </div>
                        <input id="upload-image-input" type="file" onChange={this.handleFileInput} accept="image/png,image/jpeg" hidden />
                    </div>
                </Dialog>

            </div >
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        userId: state.profile.userId
    }
}

export default connect(mapStateToProps, {
    hideToastAction,
    showErrorToastAction,
    showInfoToastAction,
    showSuccessToastAction,
    submittingAction,
    newPostCreated,
    postUpdated
})(PostDialog)


const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

