import { DefaultTheme } from 'styled-components';

// Define what props.theme will look like
const theme: DefaultTheme = {
    colors: {
        text: "rgba(0,0,0,0.9)",
        primary: "#275efe",
        primaryDark: "#013ce9"
    },
    fonts: {
        main: ` -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`
    },
    shadows: {
        soft: "0 4px 12px 0 rgba(0, 0, 0, 0.05)"
    }
};

export default theme;